/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AnswerState } from "../../Context/questionsContext";
import { Vocabulary } from "../../Utils/Vocabulary";

type CoefficientComponentProps = {
  answer: AnswerState;
  answerNumber: number;
  handleChangeCoefficient: (e: MouseEvent | any, answerNumber: number) => void;
};

// const coefficientType = ["percent", "currency"];
const coefficientType = ["%", Vocabulary.currency];

export default function CoefficientComponent(props: CoefficientComponentProps) {
  const { answer, answerNumber, handleChangeCoefficient } = props;
  return (
    <Grid container spacing={0}>
      <Grid item xs={10}>
        <TextField
          type="number"
          name="coefficientValue"
          label={Vocabulary.coefficient}
          style={{ margin: "10px 0px" }}
          value={answer.coefficientValue}
          variant="standard"
          onChange={(e) => handleChangeCoefficient(e, answerNumber)}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="standard" sx={{ marginTop: 1.25 }} fullWidth>
          <InputLabel>{Vocabulary.type}</InputLabel>
          <Select
            value={answer.coefficientType}
            name="coefficientType"
            onChange={(e) => handleChangeCoefficient(e, answerNumber)}
            label={Vocabulary.coefficientType}
          >
            {coefficientType.map((ct: any, index: number) => (
              <MenuItem key={`coefficientType_${index}`} value={ct}>
                {ct}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
