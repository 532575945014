import { Grid } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { useContext, useEffect } from "react";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
const data = [
  {
    id: "japan",
    color: "hsl(234, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 199,
      },
      {
        x: "helicopter",
        y: 273,
      },
      {
        x: "boat",
        y: 7,
      },
      {
        x: "train",
        y: 125,
      },
      {
        x: "subway",
        y: 39,
      },
      {
        x: "bus",
        y: 23,
      },
      {
        x: "car",
        y: 24,
      },
      {
        x: "moto",
        y: 251,
      },
      {
        x: "bicycle",
        y: 291,
      },
      {
        x: "horse",
        y: 170,
      },
      {
        x: "skateboard",
        y: 297,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(344, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 89,
      },
      {
        x: "boat",
        y: 138,
      },
      {
        x: "train",
        y: 38,
      },
      {
        x: "subway",
        y: 177,
      },
      {
        x: "bus",
        y: 36,
      },
      {
        x: "car",
        y: 56,
      },
      {
        x: "moto",
        y: 257,
      },
      {
        x: "bicycle",
        y: 185,
      },
      {
        x: "horse",
        y: 262,
      },
      {
        x: "skateboard",
        y: 249,
      },
      {
        x: "others",
        y: 39,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(69, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 147,
      },
      {
        x: "helicopter",
        y: 196,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 69,
      },
      {
        x: "subway",
        y: 161,
      },
      {
        x: "bus",
        y: 98,
      },
      {
        x: "car",
        y: 251,
      },
      {
        x: "moto",
        y: 153,
      },
      {
        x: "bicycle",
        y: 5,
      },
      {
        x: "horse",
        y: 239,
      },
      {
        x: "skateboard",
        y: 184,
      },
      {
        x: "others",
        y: 172,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(343, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 47,
      },
      {
        x: "helicopter",
        y: 281,
      },
      {
        x: "boat",
        y: 21,
      },
      {
        x: "train",
        y: 181,
      },
      {
        x: "subway",
        y: 201,
      },
      {
        x: "bus",
        y: 117,
      },
      {
        x: "car",
        y: 5,
      },
      {
        x: "moto",
        y: 175,
      },
      {
        x: "bicycle",
        y: 220,
      },
      {
        x: "horse",
        y: 283,
      },
      {
        x: "skateboard",
        y: 225,
      },
      {
        x: "others",
        y: 230,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(41, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 90,
      },
      {
        x: "helicopter",
        y: 263,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 34,
      },
      {
        x: "subway",
        y: 230,
      },
      {
        x: "bus",
        y: 109,
      },
      {
        x: "car",
        y: 228,
      },
      {
        x: "moto",
        y: 234,
      },
      {
        x: "bicycle",
        y: 5,
      },
      {
        x: "horse",
        y: 300,
      },
      {
        x: "skateboard",
        y: 173,
      },
      {
        x: "others",
        y: 88,
      },
    ],
  },
];

export default function Home() {
  const breadCrumbsContext = useContext(BreadCrumbsContext);

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs(["dashboard"]);
  });
  return (
    <Grid container spacing={2}>
      <div style={{ width: "800px", height: "500px" }}>
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <div style={{ width: "800px", height: "500px" }}>
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Grid>
  );
}
