import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    dashboard?: Palette["primary"];
    dashboardLight?: Palette["primary"];
    dashboardDark?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    whiteColor?: Palette["primary"];
    blackColor?: Palette["primary"];
    blueColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    redColor?: Palette["primary"];
    grayColor?: Palette["primary"];
  }
  interface PaletteOptions {
    dashboard?: PaletteOptions["primary"];
    dashboardLight?: PaletteOptions["primary"];
    dashboardDark?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    whiteColor?: PaletteOptions["primary"];
    blackColor?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    redColor?: PaletteOptions["primary"];
    grayColor?: PaletteOptions["primary"];
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: "#519E00",
    },
    secondary: {
      main: "#B4C408",
    },
    dashboard: {
      main: "#570091",
    },
    dashboardLight: {
      main: "#662e8b",
    },
    dashboardDark: {
      main: "#4a007a",
    },
    successColor: {
      main: "#12A152",
    },
    errorColor: {
      main: "#E26868",
    },
    whiteColor: {
      main: "#ffffff",
    },
    blackColor: {
      main: "#000000",
    },
    grayColor: {
      main: "#ddd",
    },
    blueColor: {
      main: "#348BB9",
    },
    orangeColor: { main: "orange" },
    redColor: { main: "red" },
  },
  typography: {
    fontSize: 12,
  },
  direction: "ltr",
});
theme = responsiveFontSizes(theme);

export default theme;
