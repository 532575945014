import React, { createContext, useState } from "react";

export const BreadCrumbsContext = createContext({
  breadCrumbs: [""],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBreadCrumbs: (breadCrumbs: Array<string>) => {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BreadCrumbsContextProvider = (props: any) => {
  const setBreadCrumbs = (breadCrumbs: Array<string>) => {
    setState({ ...state, breadCrumbs: breadCrumbs });
  };
  const initState = {
    breadCrumbs: [""],
    setBreadCrumbs: setBreadCrumbs,
  };
  const [state, setState] = useState(initState);
  return (
    <BreadCrumbsContext.Provider value={state}>
      {props.children}
    </BreadCrumbsContext.Provider>
  );
};
