import { Button, Divider, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AnswersRows from "./AnswersRows";
import { AnswerState, QuestionsContext } from "../../Context/questionsContext";
import { useContext } from "react";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "./Questionnaire.module.scss";

type AnswersTableProps = {
  answers: Array<AnswerState>;
};
export default function AnswersTable(props: AnswersTableProps) {
  const { answers } = props;
  const questionsContext = useContext(QuestionsContext);

  /**
   *
   *
   */
  function addNewAnswer() {
    answers.push({
      option: "",
      coefficientType: "LEI",
      coefficientValue: 0,
      hardStop: false,
      questions: [],
    });
    //change state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  return (
    <Paper style={{ marginTop: 10, background: "transparent" }}>
      <Grid
        container
        spacing={2}
        style={{ margin: 10 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4}>
          {Vocabulary.options}
        </Grid>
        <Grid item lg={3}>
          {Vocabulary.coefficient}
        </Grid>
        <Grid item lg={2} className={style.answerGrid}>
          {Vocabulary.hardStop}
        </Grid>
        <Grid item lg={3} className={style.answerGrid}>
          {Vocabulary.options}
        </Grid>
      </Grid>
      <Divider />
      <AnswersRows answers={answers} />
      <Button
        style={{
          margin: 10,
          color: theme.palette.whiteColor?.main,
          backgroundColor: theme.palette.dashboard?.main,
        }}
        variant="contained"
        onClick={() => addNewAnswer()}
      >
        <AddIcon style={{ marginRight: 7, marginBottom: 4 }} />
        {Vocabulary.addNewAnswer}
      </Button>
    </Paper>
  );
}
