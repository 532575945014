/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Checkbox, Grid, Paper, TextField } from "@mui/material";
import { useContext } from "react";
import { AnswerState, QuestionsContext } from "../../Context/questionsContext";
import CoefficientComponent from "./CoefficientComponent";
import AddIcon from "@mui/icons-material/Add";
import Question from "./Question";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "./Questionnaire.module.scss";

type AnswersRowsProps = {
  answers: Array<AnswerState>;
};
export default function AnswersRows(props: AnswersRowsProps) {
  const { answers } = props;
  const questionsContext = useContext(QuestionsContext);

  /**
   *
   * @param e
   * @param index
   */
  function handleChangeAnswerOption(e: any, index: number) {
    answers[index].option = e.target.value;

    //chnage state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param answerNumber
   * @param questionNumber
   */
  function deleteAnswer(answerNumber: number) {
    answers.splice(answerNumber, 1);
    //chnage state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param answerNumber
   * @param questionNumber
   */
  function addNewQuestionToAnswer(answerNumber: number) {
    if (answers[answerNumber].questions) {
      answers[answerNumber].questions.push({
        question: "",
        answerType: "singleChoice",
        images: [],
        files: [],
        answers: [
          {
            option: "",
            coefficientType: "LEI",
            coefficientValue: 0,
            hardStop: false,
            questions: [],
          },
        ],
        openCollapse: true,
      });
    } else {
      answers[answerNumber].questions = [
        {
          question: "",
          answerType: "singleChoice",
          images: [],
          files: [],
          answers: [
            {
              option: "",
              coefficientType: "LEI",
              coefficientValue: 0,
              hardStop: false,
              questions: [],
            },
          ],
          openCollapse: true,
        },
      ];
    }
    //chnage state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param e
   * @param answerNumber
   */
  function handleChangeCoefficient(e: any, answerNumber: number) {
    const answer = answers[answerNumber] as any;
    answer[e.target.name] = e.target.value;
    //chnage state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param e
   * @param answerNumber
   */
  function handleChangeHardStop(e: any, answerNumber: number) {
    answers[answerNumber].hardStop = e.target.checked;
    //chnage state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  return (
    <>
      {answers.map((answer, index) => (
        <Grid container spacing={2} style={{ padding: 15 }} key={`answers_${index}`}>
          <Grid item lg={4}>
            <TextField
              type="text"
              name="option"
              label={Vocabulary.option}
              style={{ margin: "10px 0px" }}
              value={answer.option}
              onChange={(e) => handleChangeAnswerOption(e, index)}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={3}>
            <CoefficientComponent
              answer={answer}
              answerNumber={index}
              handleChangeCoefficient={handleChangeCoefficient}
            />
          </Grid>
          <Grid item lg={2} className={style.answerGrid}>
            <Checkbox
              checked={answer.hardStop}
              onChange={(e) => handleChangeHardStop(e, index)}
              style={{ marginTop: 10 }}
            />
          </Grid>
          <Grid item lg={3} className={style.answerGrid}>
            <Button
              onClick={() => deleteAnswer(index)}
              disabled={answers.length === 1}
            >
              <DeleteIcon className={style.deleteIcon} />
            </Button>
            <Button onClick={() => addNewQuestionToAnswer(index)}>
              <AddIcon style={{ marginRight: 7, marginBottom: 4 }} />
              {Vocabulary.newQuestion}
            </Button>
          </Grid>
          {answer.questions && answer.questions.length !== 0 ? (
            <Grid item lg={12} className={style.questionForAnswers}>
              {answer.questions.map((question: any, index: number) => (
                <Paper
                  key={`answers.questions_${index}`}
                  className={style.questionPaper}
                >
                  <Question
                    questionNumber={index}
                    questions={answer.questions}
                    answerText={answer.option}
                  />
                </Paper>
              ))}
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ))}
    </>
  );
}
