/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "./Vocabulary";

type indexedType = {
  [index: string]: any;
};
export const UrlEnum: indexedType = {
  login: "/api/auth/login",
  register: "/api/auth/register",
  users: "/api/users",
  devices: "/api/devices",
  importDevices: "/api/devices/import",
  questionnaire: "/api/questionnaires",
  questionnaireByPersonalDataTemplateId:
    "/api/questionnaires/get/questionnaire/personalDataTemplateId",
  questionnaireTemplates: "/api/questionnaireTemplates",
  personalDataTemplates: "/api/personalDataTemplates",
  emailTemplates: "/api/emailTemplates",
  searchGSMDevice: "/api/searchGSMDevice",
  getGSMDevice: "/api/getGSMDevice",
  orders: "/api/orders",
  trashedOrders: "/api/orders/trashedOrders",
  orderStatus: "/api/orderStatus",
  sendEmail: "/api/orders/sendEmail/saveDocuments",
  logs: "/api/logs",
  reports: "/api/reports",
  documents: "/api/documents",
};

export const LocalUrlEnum = {
  orders: "/orders/",
  login: "/login/",
  register: "/register/",
  logout: "/logout/",
  requests: "/requests/",
  devices: "/devices/",
  questionnaires: "/questionnaires/",
  questionnairesTemplates: "/templates/questionnaires/",
  personalData: "/templates/personalData/",
  emailTemplates: "/templates/email",
  users: "/users/",
  orderStatus: "/orderStatus",
  trashedOrders: "/trashedOrders",
  logs: "/logs",
  reports: "/reports",
  reportsTab: ["status", "paymentType"],
};

export const paymentTypeEnum = {
  normal: "NORMAL",
  consignment: "CONSIGNATIE",
};

export const titleForReportsTotal = {
  NORMAL: "Plata in 24-48h",
  CONSIGNATIE: "Plata in 30 zile",
} as any;
export const ReportsTab = {
  status: "status",
  paymentType: "paymentType",
};

export const StatusEnum = {
  estimated: "Estimat",
  unsentMail: "Mail netrimis",
  liquid: "Contact cu lichide",
};

export const localStatuses = [
  {
    name: StatusEnum.estimated,
  },
  {
    name: StatusEnum.unsentMail,
  },
  {
    name: StatusEnum.liquid,
  },
];

export const europeanDate = "DD/MM/YYYY";

export function getRoles(role: string | number) {
  if (role === "user")
    return {
      id: 2,
      label: Vocabulary.user,
    };
  else if (role === "admin") return { id: 1, label: Vocabulary.admin };
  else if (role === "serviceTechnician")
    return { id: 3, label: Vocabulary.serviceTechnician };

  if (role === 1) return "admin";
  else if (role === 2) return "user";
  else if (role === 3) return "serviceTechnician";
}

/**
 *
 * @param file
 * @returns
 */
export function resizeImage(file: any) {
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.src = readerEvent.target?.result as string;
        image.onload = function (imageEvent) {
          const imageFile = resize(image, file.name);
          resolve(imageFile);
        };
        image.onerror = function (err) {
          reject(err);
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
}

/**
 *
 * @param image
 * @param fileName
 * @returns
 */
function resize(image: any, fileName: any) {
  const canvas = document.createElement("canvas");
  let { width } = image,
    { height } = image;
  const maxWidth = width > 200 ? 200 : width;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxWidth) {
      width *= maxWidth / height;
      height = maxWidth;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas?.getContext("2d")?.drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/png");
  const resizedImage = dataURLToBlob(dataUrl);
  return blobToFile(resizedImage, fileName);
}

/**
 *
 * @param {*} dataURL
 * @param {*} fileName
 * @returns
 */
export function dataURLToBlob(dataURL: any) {
  const BASE64_MARKER = ";base64,";

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  const rawLength = raw.length;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: contentType });
  return blob;
}

/**
 *
 * @param {*} ab
 * @param {*} fileName
 * @returns
 */
function blobToFile(ab: any, fileName: any) {
  const f = new File([ab], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return f;
}

export async function download(blob: Blob | MediaSource, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
