/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Checkbox,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Delete, Edit } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  LocalUrlEnum,
  UrlEnum,
  download,
  paymentTypeEnum,
} from "../../Utils/Utils";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { getData } from "../../Services/getData";
import OrderModal from "./OrderModal";
import { deleteData } from "../../Services/deleteData";
import { useLocation, useNavigate } from "react-router-dom";
import StatusOrder from "./StatusOrder";
import moment from "moment";
import theme from "../../Theme/Theme";
import DateFilters from "./DateFilters";
import { BadgeContext } from "../../Context/badgeContext";

export default function Orders() {
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const badgeContext = useContext(BadgeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteIds, setDeleteIds] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [state, setState] = useState<any>({
    orders: [],
    orderNumber: 0,
    personalDataKeys: [],
    page: 0,
    perPage: 20,
    sort: { name: "createdAt", direction: "desc" },
    filter: "Niciun filtru",
    startDate: moment().subtract(60, "d"),
    endDate: moment(),
    forPayment: false,
    preview: false,
    selectedRequest: {},
    loading: false,
  });
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  /**
   *
   */
  useEffect(() => {
    getData(UrlEnum.orderStatus)
      .then((res) => {
        setStatusEnum(res.data);
      })
      .catch((err) => {
        //
      });
  }, []);

  /**
   *
   */
  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs([`orders`]);
    const search = location.search.split("?");
    const newState = Object.assign({}, state);
    newState.forPayment = false;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") {
          newState[values[0]] = +values[1] - 1;
        } else if (values[0] === "sort") {
          const sort = values[1].split(",");
          newState[values[0]] = { name: sort[0], direction: sort[1] };
        } else if (values[0] === "filter") {
          newState[values[0]] = decodeURI(values[1]);
        } else if (values[0] === "forPayment") {
          newState[values[0]] = values[1] === "true" ? true : false;
        } else {
          newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        }
      }
    });
    getOrders(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, badgeContext.ordersNumber]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  async function getOrders(newState: any) {
    setLoading(true);
    let url = `${UrlEnum.orders}/${newState.page ? newState.page : 0}/${
      newState.perPage ? newState.perPage : state.perPage
    }/${newState.filter ? newState.filter : state.filter}/${
      newState.sort.name ? newState.sort.name : state.sort.name
    },${
      newState.sort.direction ? newState.sort.direction : state.sort.direction
    }/${newState.startDate ? newState.startDate : state.startDate}/${
      newState.endDate ? newState.endDate : state.endDate
    }`;
    if (newState.search) {
      url += `/${newState.search}`;
    }
    if (newState.forPayment) {
      url = `${UrlEnum.orders}/forPayment/${
        newState.page ? newState.page : 0
      }/${newState.perPage ? newState.perPage : state.perPage}`;
    }
    await getData(url)
      .then((res) => {
        let personalDataKeys = [] as any;
        res.data.orders.forEach((value: any) => {
          personalDataKeys.push(...Object.keys(value.personalData));
        });
        personalDataKeys = [...new Set(personalDataKeys)];
        setState({
          ...state,
          orders: res.data.orders,
          personalDataKeys: personalDataKeys,
          orderNumber: res.data.ordersNumber,
          filter: newState.filter ? newState.filter : state.filter,
          sort: {
            name: newState.sort.name ? newState.sort.name : state.sort.name,
            direction: newState.sort.direction
              ? newState.sort.direction
              : state.sort.direction,
          },
          startDate: moment(
            newState.startDate ? newState.startDate : state.startDate
          ),
          endDate: moment(newState.endDate ? newState.endDate : state.endDate),
          page: newState.page ? newState.page : 0,
          perPage: newState.perPage ? newState.perPage : state.perPage,
          forPayment: newState.forPayment ? newState.forPayment : false,
        });
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
        setLoading(false);
      });
  }

  /**
   *
   */
  async function handleDelete() {
    setLoading(true);
    await deleteData(`${UrlEnum.orders}/${userName}`, {
      data: { ids: deleteIds },
    })
      .then(async (res) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifySuccess(Vocabulary.deleteSuccess);
        await getOrders(state);
      })
      .catch((err) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifyError(Vocabulary.deleteError);
      });
  }

  /**
   *
   */
  const requestHeaders = [
    {
      label: Vocabulary.numberOrder,
      name: "orderNumber",
      options: {
        customBodyRender: (orderNumber: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {orderNumber}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.deviceName,
      name: "deviceDetails",
      options: {
        sort: true,
        customBodyRender: (deviceDetails: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {deviceDetails.deviceName}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: true,
        customBodyRender: (status: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {status.order}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.paymentType,
      name: "paymentType",
      options: {
        customBodyRender: (paymentType: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {paymentType === paymentTypeEnum.consignment
                ? "Plata in 30 zile"
                : paymentType}
            </Typography>
          );
        },
      },
    },
    ...state.personalDataKeys.map((key: string) => ({
      label: key.toUpperCase(),
      name: "personalData",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {value[key]}
            </Typography>
          );
        },
      },
    })),
    state.forPayment
      ? {
          label: "Zile rămase",
          name: "daysSinceStatusChange",
          options: {
            sort: false,
            customBodyRender: (value: any, tableMeta: any) => {
              return (
                <Typography style={{ fontWeight: "bold" }}>
                  {30 - value} Zile
                </Typography>
              );
            },
          },
        }
      : "",

    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {moment(value).format("DD-MM-YYYY HH:mm")}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.options.toUpperCase(),
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `${LocalUrlEnum.orders}${state.orders[rowIndex]._id}`
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(true);
                      setDeleteIds(state.orders[rowIndex]._id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: false,
      filter: false,
      print: false,
      download: false,
      sortOrder: {
        name: state.sort.name,
        direction: state.sort.direction,
      } as any,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.orderNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: true,
      serverSide: true,
      sort: true,
      customToolbarSelect: (selectedRows: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label
              style={{
                margin: "0px 5px",
                color: "red",
                cursor: "pointer",
                verticalAlign: "middle",
              }}
              onClick={() => downloadPdf(selectedRows)}
            >
              {Vocabulary.downloadPdf}
            </label>
            <PictureAsPdfIcon
              style={{ margin: "0px 5px", color: "red", cursor: "pointer" }}
              onClick={() => downloadPdf(selectedRows)}
            />
            <Delete
              style={{ margin: "0px 10px", cursor: "pointer" }}
              onClick={() => deleteMultiple(selectedRows)}
            />
          </div>
        );
      },
      onRowClick: (rowData: any, rowState: any) => {
        navigate(
          `${LocalUrlEnum.orders}${state.orders[rowState.rowIndex]._id}`
        );
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        const status = statusEnum.find(
          (stEnum: any) =>
            stEnum.name.toLowerCase() ===
            state.orders[rowIndex].status.order.toLowerCase()
        );
        if (status) {
          if (
            state.orders[rowIndex].paymentType === paymentTypeEnum.consignment
          )
            return {
              style: {
                backgroundColor: status.color,
                borderLeft: "5px solid rgba(81,158,0,1)",
                cursor: "pointer",
              },
            };
          return {
            style: {
              backgroundColor: status.color,
              borderLeft: `5px solid ${status.color}`,
              cursor: "pointer",
            },
          };
        }
        if (state.orders[rowIndex].status.order === "Contact cu lichide")
          return {
            style: {
              backgroundColor: theme.palette.grayColor?.main,
              borderLeft: "5px solid #C60000",
              cursor: "pointer",
            },
          };
        return {
          style: {
            backgroundColor: theme.palette.grayColor?.main,
            cursor: "pointer",
          },
        };
      },
      onChangePage: (page: any) => {
        if (state.forPayment) {
          if (state.search)
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?forPayment=${state.forPayment}?search=${state.search}`
            );
          else
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?forPayment=${state.forPayment}`
            );
        } else {
          if (state.search)
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}?search=${
                state.search
              }`
            );
          else
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}`
            );
        }
        window.scrollTo(0, 0);
      },
      /**
       *
       * @param numberOfRows
       */
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (state.forPayment) {
          if (state.search)
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?forPayment=${
                state.forPayment
              }?search=${state.search}`
            );
          else
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?forPayment=${
                state.forPayment
              }`
            );
        } else {
          if (state.search)
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?filter=${state.filter}?sort=${
                state.sort.name
              },${state.sort.direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}?search=${state.search}`
            );
          else
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?filter=${state.filter}?sort=${
                state.sort.name
              },${state.sort.direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}`
            );
        }
        window.scrollTo(0, 0);
      },
      /**
       *
       * @param search
       */
      onSearchChange: async (search: string | null) => {
        if (state.forPayment) {
          //
        } else {
          if (search) {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}?search=${search}`
            );
          } else {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}`
            );
          }
        }
      },
      /**
       *
       */
      onSearchClose: async () => {
        if (state.forPayment) {
          //
        } else {
          navigate(
            `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
              state.perPage
            }?filter=${state.filter}?sort=${state.sort.name},${
              state.sort.direction
            }?startDate=${moment(state.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}`
          );
        }
      },
      onColumnSortChange: async (changedColumn: string, direction: string) => {
        if (state.forPayment) {
          //
        } else {
          if (state.search) {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${
                state.filter
              }?sort=${changedColumn},${direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}?search=${state.search}`
            );
          } else {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${
                state.filter
              }?sort=${changedColumn},${direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}`
            );
          }
        }
      },
    };
  }

  /**
   *
   * @param selectedRows
   */
  function downloadPdf(selectedRows: any) {
    setLoading(true);
    const ids = selectedRows.data.map(
      (d: any) => state.orders[d.dataIndex]._id
    );

    const token = localStorage.getItem("access_token");
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderIds: ids }),
    };
    const timestamp = Date.now();
    const filename = `Orders_${timestamp}.pdf`;
    fetch(`${UrlEnum.documents}/generatePdf`, options)
      .then((response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        let suggestedFilename: any = filename;
        if (contentDisposition) {
          const filenameMatch: any =
            contentDisposition.match(/filename="?(.+)""?/);
          if (filenameMatch.length > 1) {
            suggestedFilename = filenameMatch[1];
          }
        }
        if (response.status === 200) {
          response.blob().then((blob: Blob) => {
            download(blob, suggestedFilename);
            setLoading(false);
            deletePDF(suggestedFilename);
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  async function deletePDF(fileName: string) {
    await deleteData(`${UrlEnum.documents}/deletePdf/${fileName}`);
  }
  /**
   *
   * @param rowsDeleted
   */
  function deleteMultiple(rowsDeleted: any) {
    const ids = rowsDeleted.data.map((d: any) => state.orders[d.dataIndex]._id);
    setDeleteIds(ids);
    setOpenDeleteModal(true);
  }
  /**
   *
   * @param key
   * @param value
   */
  function handleChangeStatus(
    id: string | null | undefined,
    value: string | undefined
  ) {
    setState({ ...state, filter: value });
    if (state.search)
      navigate(
        `${LocalUrlEnum.orders}?page=1?perPage=${
          state.perPage
        }?filter=${value}?sort=${state.sort.name},${
          state.sort.direction
        }?startDate=${moment(state.startDate).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}?search=${
          state.search
        }`
      );
    else
      navigate(
        `${LocalUrlEnum.orders}?page=1?perPage=${
          state.perPage
        }?filter=${value}?sort=${state.sort.name},${
          state.sort.direction
        }?startDate=${moment(state.startDate).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}`
      );
  }

  return (
    <>
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <DateFilters state={state} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 25,
                height: 25,
                background: "linear-gradient(90deg,#FF7A7A 0%,#C60000 100%)",
                borderRadius: "4px",
              }}
            ></div>
            <p style={{ marginLeft: 10 }}>Contact cu lichide</p>
          </div>
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 25,
                height: 25,
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                borderRadius: "4px",
              }}
            ></div>
            <p style={{ marginLeft: 10 }}>Plata in 30 zile</p>
          </div>
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={state.forPayment}
              onChange={(e) => {
                if (e.target.checked) {
                  navigate(
                    `${LocalUrlEnum.orders}?page=1?perPage=${state.perPage}?forPayment=${e.target.checked}`
                  );
                } else {
                  navigate(
                    `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                      state.perPage
                    }?filter=${state.filter}?sort=${state.sort.name},${
                      state.sort.direction
                    }?startDate=${moment(state.startDate).format(
                      "YYYY-MM-DD"
                    )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}`
                  );
                }
              }}
            ></Checkbox>
            <p style={{ marginLeft: 10 }}>De plată</p>
          </div>
        </Grid>
        <Grid item xs={4} md={6} lg={6}>
          <StatusOrder
            isOrder={false}
            status={state.filter}
            handleChangeStatus={handleChangeStatus}
            name="applyFilters"
            noFilter={{
              name: "Niciun filtru",
              color: theme.palette.primary?.main,
            }}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        title={Vocabulary.orders}
        data={state.orders}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      {openDeleteModal ? (
        <OrderModal
          title={Vocabulary.deleteOrder}
          openModal={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          handleModifyValues={handleDelete}
        />
      ) : (
        ""
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
