/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import { ValidatorForm } from "react-material-ui-form-validator"; //TextValidator
import { Button, Dialog, DialogTitle } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import classes from "./Users.module.scss";

type UserModalProps = {
  open: boolean;
  title: string;
  cancelButton: string;
  submitButton: string;
  handleClose: () => void;
  handleSubmit: () => void;
  children: any;
};
function UserModal(props: UserModalProps) {
  const matches = useMediaQuery("(max-width:600px)");
  const {
    open,
    title,
    cancelButton,
    submitButton,
    handleClose,
    handleSubmit,
    children,
  } = props;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ sx: { maxWidth: "unset" } }}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <p style={{ margin: 10 }}>
          {title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p className={classes.closeIcon}>
          <CloseIcon onClick={handleClose} />{" "}
        </p>
      </DialogTitle>
      <ValidatorForm onSubmit={handleSubmit} style={{ padding: 10 }}>
        <div>{children}</div>
        <div className={matches ? classes.buttons_sx : classes.buttons}>
          <Button
            className={matches ? classes.buttonCancel_sx : classes.buttonCancel}
            onClick={handleClose}
            fullWidth
          >
            {cancelButton}
          </Button>
          <Button
            type="submit"
            className={matches ? classes.buttonSave_sx : classes.buttonSave}
            fullWidth
          >
            {submitButton}
          </Button>
        </div>
      </ValidatorForm>
    </Dialog>
  );
}

export default UserModal;
