/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import PersonalDataDetails from "./PersonalDataDetails";
import theme from "../../Theme/Theme";
import { useRef, useState } from "react";
import { paymentTypeEnum } from "../../Utils/Utils";
type PriceAndPaymentProps = {
  order: any;
  handleChangePaymentType: (value: string) => void;
  handleChangePrice: (event: any) => void;
  handleChangePersonalDataValue: (key: string, value: string | any) => void;
  handleChangePaymentInfoValue: (key: string, value: string | any) => void;
};
export default function PriceAndPayment(props: PriceAndPaymentProps) {
  const {
    order,
    handleChangePrice,
    handleChangePaymentType,
    handleChangePersonalDataValue,
    handleChangePaymentInfoValue,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<any>(null); // Ref to the button
  const open = Boolean(anchorEl);

  // Get the width of the button
  const menuStyle = buttonRef.current
    ? {
        width: buttonRef.current.offsetWidth,
      }
    : {};
  /**
   *
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   *
   */
  const handleClose = (value: string | null) => {
    setAnchorEl(null);
    if (value) handleChangePaymentType(value);
  };

  return (
    <Grid item xs={12} md={12} lg={12} style={{ margin: 10 }}>
      <Paper
        style={{
          padding: 15,
        }}
      >
        <h3>{Vocabulary.priceAndPayment}</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <div style={{ padding: 15 }}>
              <div style={{ display: "flex" }}>
                <TextField
                  type="number"
                  label={Vocabulary.price}
                  variant="standard"
                  fullWidth
                  value={order ? order.estimatedPrice : 0}
                  onChange={(e: any) => handleChangePrice(e)}
                  style={{ margin: "10px 0px" }}
                />
                <TextField
                  type="text"
                  label={Vocabulary.currencyName}
                  variant="standard"
                  defaultValue="LEI"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ margin: "10px 0px" }}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  label={Vocabulary.paymentMethod}
                  variant="standard"
                  fullWidth
                  // value={}
                  // onChange={}
                  style={{ margin: "10px 0px" }}
                />
              </div>
              <div>
                <Button
                  ref={buttonRef}
                  onClick={handleClick}
                  fullWidth
                  style={{
                    marginTop: 30,
                    textAlign: "center",
                    color: theme.palette.whiteColor?.main,
                    background:
                      order && order.paymentType === paymentTypeEnum.normal
                        ? "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)"
                        : "linear-gradient(90deg,#cc80ff 0%,#90f 65%)",
                  }}
                >
                  <h3
                    style={{
                      color: theme.palette.whiteColor?.main,
                      padding: "0px 10px",
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                  >
                    <span style={{ fontWeight: "normal" }}>
                      {" "}
                      {Vocabulary.paymentType}:{" "}
                    </span>
                    {order && order.paymentType
                      ? order.paymentType === paymentTypeEnum.consignment
                        ? "Plata in 30 zile"
                        : order.paymentType
                      : ""}
                  </h3>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleClose(null)}
                  PaperProps={{
                    style: menuStyle,
                  }}
                >
                  <MenuItem
                    onClick={() => handleClose(paymentTypeEnum.consignment)}
                  >
                    {paymentTypeEnum.consignment}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(paymentTypeEnum.normal)}>
                    {paymentTypeEnum.normal}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Grid>
          <Divider style={{ borderWidth: 1 }} />
          <PersonalDataDetails
            title={Vocabulary.personalData}
            details={order ? order.personalData : ""}
            gridSize={3}
            handleChangeValue={handleChangePersonalDataValue}
          />
          <PersonalDataDetails
            title={Vocabulary.paymentInfo}
            details={order && order.paymentInfo ? order.paymentInfo : ""}
            gridSize={2}
            handleChangeValue={handleChangePaymentInfoValue}
          />
        </Grid>
      </Paper>
    </Grid>
  );
}
