/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from "../../Components/Modal";
import EmailTemplate from "./EmailTemplate";

type EmailTemplateModalProps = {
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
  data: any;
};

export default function EmailTemplateModal(props: EmailTemplateModalProps) {
  const { open, onClose, data } = props;

  return (
    <Modal
      title={"Șablon email"}
      open={open}
      onClose={() => onClose(false)}
      restModalProps={{
        maxWidth: "xl",
      }}
      maxWidth={1535}
    >
      <EmailTemplate data={data} onClose={onClose} fromOrder={false} />
    </Modal>
  );
}
