/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../../Theme/Theme";
import { DataTableState } from "../../Types/DataTableType";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "../Devices/Devices.module.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PersonalDataTemplate from "./PersonalDataTemplate";
import { getData } from "../../Services/getData";
import { Delete, Edit } from "@mui/icons-material";
import DeleteQuestionnaireModal from "../Questionnaire/DeleteQuestionnaireModal";
import { deleteData } from "../../Services/deleteData";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function PersonalDataTemplates() {
  const dataTableHeader = [
    {
      label: Vocabulary.templateName.toUpperCase(),
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.paymentInfo.toUpperCase(),
      name: "paymentInfo",
      options: {
        sort: false,
        setCellProps: () => ({
          align: "left",
        }),
        customBodyRender: (paymentInfo: any) => (
          <Typography>
            {paymentInfo ? (
              <span
                style={{
                  fontSize: 20,
                  padding: 0,
                  marginLeft: 10,
                  color: theme.palette.primary?.main,
                }}
              >
                ✔
              </span>
            ) : (
              ""
            )}
          </Typography>
        ),
      },
    },
    {
      label: Vocabulary.options.toUpperCase(),
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{ marginTop: -10, marginBottom: -10, textAlign: "center" }}
            >
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      setEditedModel(state.valuesPerPage[rowIndex]);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(true);
                      setDeleteId(state.valuesPerPage[rowIndex]._id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedModel, setEditedModel] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const [state, setState] = useState<DataTableState>({
    valuesPerPage: [],
    valuesNumber: 0,
    page: 1,
    perPage: 20,
    search: null,
    order: "asc",
    filter: null,
    loading: false,
  });
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs([`templates/personalData`]);
    const search = location.search.split("?");
    const newState = Object.assign({}, state) as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getPersonalData(newState.page, newState.perPage, newState.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (editedModel) {
      setModalOpen(true);
    }
  }, [editedModel]);

  /**
   *
   */
  async function getPersonalData(
    page: number,
    perPage: number,
    search?: string | null
  ) {
    setLoading(true);
    let url = `${UrlEnum.personalDataTemplates}/${page}/${perPage}`;
    if (search) {
      url = `${UrlEnum.personalDataTemplates}/${page}/${perPage}/${search}`;
    }
    await getData(url)
      .then((res: any) => {
        setState({
          ...state,
          valuesPerPage: res.data.personalDataTemplates,
          valuesNumber: res.data.personalDataTemplatesNumber,
          page: page,
          perPage: perPage,
          search: search || null,
        });
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      rowsPerPage: state.perPage,
      page: state.page,
      count: state.valuesNumber,
      search: true,
      serverSide: true,
      sort: true,
      setRowProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      onRowClick: (rowData: any, rowState: any) => {
        setEditedModel(state.valuesPerPage[rowState.rowIndex]);
      },
      onChangePage: (page: any) => {
        navigate(
          `${LocalUrlEnum.personalData}?page=${page + 1}?perPage=${
            state.perPage
          }`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `${LocalUrlEnum.personalData}?page=${1}?perPage=${numberOfRows}`
        );
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          navigate(
            `${LocalUrlEnum.personalData}?page=${state.page + 1}?perPage=${
              state.perPage
            }?search=${search}`
          );
        } else {
          setState({ ...state, search: null });
          navigate(
            `${LocalUrlEnum.personalData}?page=${state.page + 1}?perPage=${
              state.perPage
            }`
          );
        }
      },
      onSearchClose: async () => {
        setState({ ...state, search: null });
        navigate(
          `${LocalUrlEnum.personalData}?page=${state.page + 1}?perPage=${
            state.perPage
          }`
        );
      },
    };
  }

  /**
   *
   */
  function handleDelete() {
    setLoading(true);
    getData(`${UrlEnum.questionnaireByPersonalDataTemplateId}/${deleteId}`)
      .then((res) => {
        if (res.data.length === 0) {
          deleteData(`${UrlEnum.personalDataTemplates}/${deleteId}/${userName}`)
            .then(async (res) => {
              await getPersonalData(state.page, state.perPage, state.search);
              setLoading(false);
              setOpenDeleteModal(false);
              notifySuccess(Vocabulary.deleteSuccess);
            })
            .catch((err) => {
              setLoading(false);
              setOpenDeleteModal(false);
              notifyError(Vocabulary.deleteError);
            });
        } else {
          setLoading(false);
          setOpenDeleteModal(false);
          notifyError(Vocabulary.deletePersonalDataTemplates);
        }
      })
      .catch((err) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifyError(Vocabulary.deleteError);
      });
  }

  return (
    <>
      <ToastContainer />
      <div className={style.devicesBtn}>
        <Button
          variant="contained"
          size="large"
          style={{
            color: theme.palette.whiteColor?.main,
            backgroundColor: theme.palette.dashboard?.main,
          }}
          onClick={() => setModalOpen(true)}
        >
          <AddCircleIcon />
          &nbsp;&nbsp;
          {Vocabulary.newTemplate}
        </Button>
      </div>
      <MUIDataTable
        title={Vocabulary.personalData}
        data={state.valuesPerPage}
        columns={dataTableHeader}
        options={getTableOptions()}
      />
      {modalOpen && (
        <PersonalDataTemplate
          open={modalOpen}
          onClose={async (shouldRefetch) => {
            setModalOpen(false);
            setEditedModel(null);
            if (shouldRefetch) {
              await getPersonalData(state.page, state.perPage, state.search);
            }
          }}
          data={editedModel}
        />
      )}

      {openDeleteModal ? (
        <DeleteQuestionnaireModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          handleDelete={handleDelete}
          title={Vocabulary.deleteTemplates}
        />
      ) : (
        ""
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
