/* eslint-disable @typescript-eslint/no-explicit-any */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "../../Components/Modal";
import { read, utils } from "xlsx";
import { postData } from "../../Services/postData";
import { UrlEnum } from "../../Utils/Utils";
import { DeviceState } from "./Device";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Theme/Theme";
// import { DeviceState } from "./Device";
const sheetHeader = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const headers = [
  "deviceType",
  "manufacturer",
  "model",
  "deviceName",
  "storage",
  "color",
  "price",
];
type ImportExcelModalProps = {
  setLoading: (loading: boolean) => void;
  openImportModal: boolean;
  setOpenImportModal: (openImportModal: boolean) => void;
};
const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv", "txt", "ods"]
  .map(function (x) {
    return `.${x}`;
  })
  .join(",");

export default function ImportExcelModal(props: ImportExcelModalProps) {
  const [fileName, setFileName] = useState<any>(null);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";
  /**
   *
   * @param e
   */
  function handleChange(e: any) {
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0]);
    }
  }

  /**
   *
   * @param headerRow
   * @param index
   * @returns
   */
  function getNewHeaderName(headerRow: any, index: string) {
    if (headerRow[index]) {
      headerRow[index] = headerRow[index].replaceAll(" ", "");
      const found = headers.find((h: any) =>
        headerRow[index].toUpperCase().includes(h.toUpperCase())
      );
      if (found)
        return {
          cell: index,
          value: found,
        };
    }
    return "";
  }

  /**
   *
   * @param headerRow
   * @returns
   */
  function getNewHeader(headerRow: any) {
    const newHeader = new Array<any>();
    sheetHeader.forEach((sh) => {
      if (getNewHeaderName(headerRow, sh) !== "")
        newHeader.push(getNewHeaderName(headerRow, sh));
    });
    return newHeader;
  }

  /**
   *
   * @param devices
   * @param row
   * @returns
   */
  function hasTheSameName(devices: any, row: any) {
    const devicesName = Object.keys(devices);
    const index = devicesName.findIndex(
      (dv) => dv === row.deviceName.toUpperCase()
    );
    if (index >= 0) return true;
    return false;
  }

  /**
   *
   * @param device
   * @param row
   * @returns
   */
  function hasTheSameStorage(device: DeviceState, row: any) {
    return device.configurations.findIndex((config) =>
      config.storage.includes(row.storage)
    );
  }

  /**
   *
   * @param isAnyColorName
   * @param colors
   * @param price
   * @param color
   * @returns
   */
  function getColors(
    isAnyColorName: boolean,
    colors: any,
    price: string,
    color: string
  ) {
    const newColors = [...colors];
    if (isAnyColorName) {
      const colorIndex = colors.findIndex(
        (color: string) => color === "Orice culoare"
      );
      if (colorIndex >= 0) {
        newColors[colorIndex].price = parseFloat(price.replace(",", "."));
      }
    } else {
      newColors.push({
        color: color.toUpperCase(),
        price: parseFloat(price.replace(",", ".")),
      });
    }
    return newColors;
  }

  /**
   *
   * @param isAnyColorName
   * @param configurations
   * @param row
   * @returns
   */
  function getStorages(isAnyColorName: boolean, configurations: any, row: any) {
    const newConfigurations = [...configurations];

    if (isAnyColorName) {
      newConfigurations.push({
        storage: row.storage,
        colors: [
          {
            color: "Orice culoare",
            price: parseFloat(row.price.replace(",", ".")),
          },
        ],
      });
    } else {
      newConfigurations.push({
        storage: row.storage,
        colors: [
          {
            color: "Orice culoare",
            price: "",
          },
          {
            color: row.color.toUpperCase(),
            price: parseFloat(row.price.replace(",", ".")),
          },
        ],
      });
    }
    return newConfigurations;
  }

  function getNewDevices(isAnyColorName: boolean, row: any) {
    let newColors = [] as any;
    if (isAnyColorName)
      newColors = [
        {
          color: "Orice culoare",
          price: parseFloat(row.price.replace(",", ".")),
        },
      ];
    else
      newColors = [
        {
          color: "Orice culoare",
          price: "",
        },
        {
          color: row.color.toUpperCase(),
          price: parseFloat(row.price.replace(",", ".")),
        },
      ];
    return {
      deviceName: row.deviceName,
      deviceType: row.deviceType,
      manufacturer: row.manufacturer,
      model: row.model,
      configurations: [
        {
          storage: row.storage,
          colors: newColors,
        },
      ],
      images: [],
      defaultStorages: `${row.storage}, `,
      defaultColors: `Orice culoare, `,
      paymentMethod: "",
    };
  }
  /**
   *
   */
  function importDevices() {
    props.setLoading(true);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      // get just first row
      const wb = read(event.target.result, { sheetRows: 1 });

      // get all rows
      const wbAll = read(event.target.result);
      const sheets = wb.SheetNames;
      const devices = {} as any;
      if (sheets.length) {
        const headerRow = utils.sheet_to_json(wb.Sheets[sheets[0]], {
          header: sheetHeader,
        });
        //change excel header
        const newHeader = getNewHeader(headerRow[0]);
        newHeader.forEach((header: any) => {
          utils.sheet_add_aoa(wbAll.Sheets[sheets[0]], [[header.value]], {
            origin: `${header.cell}1`,
          });
        });

        //extract rows
        const rows = utils.sheet_to_json(wbAll.Sheets[sheets[0]]) as any;

        for (let i = 1; i < rows.length; i++) {
          const isAnyColorName =
            rows[i].color.toUpperCase().trim() === "ANY COLOR";
          //has the same name
          const isTheSameName = hasTheSameName(devices, rows[i]);
          //yes
          if (isTheSameName) {
            //has the same storage
            const indexConfiguration = hasTheSameStorage(
              devices[rows[i].deviceName.toUpperCase()],
              rows[i]
            );
            //yes
            if (indexConfiguration >= 0) {
              devices[rows[i].deviceName.toUpperCase()].configurations[
                indexConfiguration
              ].colors = getColors(
                isAnyColorName, // is ANY COLOR or not
                devices[rows[i].deviceName.toUpperCase()].configurations[
                  indexConfiguration
                ].colors, // colors until now
                rows[i].price, //price
                rows[i].color // color
              );

              // set default colors
              if (
                !devices[
                  rows[i].deviceName.toUpperCase()
                ].defaultColors.includes(`${rows[i].color}`) &&
                !isAnyColorName
              )
                devices[
                  rows[i].deviceName.toUpperCase()
                ].defaultColors += `${rows[i].color}, `;

              // same storage -> no
            } else {
              // get storages
              devices[rows[i].deviceName.toUpperCase()].configurations =
                getStorages(
                  isAnyColorName,
                  devices[rows[i].deviceName.toUpperCase()].configurations,
                  rows[i]
                );

              // set default colors
              if (
                !devices[
                  rows[i].deviceName.toUpperCase()
                ].defaultColors.includes(`${rows[i].color}`) &&
                !isAnyColorName
              )
                devices[
                  rows[i].deviceName.toUpperCase()
                ].defaultColors += `${rows[i].color}, `;

              // set default storages
              devices[
                rows[i].deviceName.toUpperCase()
              ].defaultStorages += `${rows[i].storage}, `;
            }
            // same name -> no
          } else {
            //get new device
            const newDevice = getNewDevices(isAnyColorName, rows[i]) as any;
            devices[newDevice.deviceName.toUpperCase()] = newDevice;
          }
        }
        devices.userName = userName;
        postData(UrlEnum.importDevices, devices)
          .then((res) => {
            props.setOpenImportModal(false);
            props.setLoading(false);
            notifySuccess(Vocabulary.addSuccess);
            window.location.reload();
          })
          .catch((err) => {
            props.setOpenImportModal(false);
            props.setLoading(false);
            notifyError(Vocabulary.addError);
          });
      }
    };
    reader.readAsArrayBuffer(fileName);
  }
  return (
    <>
      <ToastContainer />
      <Modal
        open={props.openImportModal}
        onClose={() => props.setOpenImportModal(false)}
        title={Vocabulary.devicesImport}
      >
        <input
          type="file"
          id="file"
          name="filename"
          accept={SheetJSFT}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          type="submit"
          style={{ margin: 20, color: theme.palette.whiteColor?.main }}
          size="large"
          color="primary"
          onClick={importDevices}
        >
          {Vocabulary.import}
        </Button>
      </Modal>
    </>
  );
}
