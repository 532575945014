/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Typography } from "@mui/material";
import { LocalUrlEnum, paymentTypeEnum } from "../../Utils/Utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import excel from "./microsoft-excel.svg";

const fileExtension = ".xlsx";
const fileType =
  "application/vnd.openxml-officedocument.spreadsheetml.sheet;charset=UTF-8";

type ReportsProps = {
  filters: any;
  state: any;
};
export default function Reports(props: ReportsProps) {
  const { filters, state } = props;
  const navigate = useNavigate();

  /**
   *
   */
  const header = [
    {
      label: Vocabulary.numberOrder,
      name: "orderNumber",
    },
    {
      label: Vocabulary.deviceName,
      name: "deviceDetails",
      options: {
        sort: true,
        customBodyRender: (deviceDetails: any, tableMeta: any) => {
          return <Typography>{deviceDetails.deviceName}</Typography>;
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: true,
        customBodyRender: (status: any, tableMeta: any) => {
          return <Typography>{status.order}</Typography>;
        },
      },
    },
    {
      label: Vocabulary.paymentType,
      name: "paymentType",
      options: {
        customBodyRender: (paymentType: any, tableMeta: any) => {
          return (
            <Typography>
              {paymentType === paymentTypeEnum.consignment
                ? "Plata in 30 zile"
                : paymentType}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Typography>{moment(value).format("DD-MM-YYYY HH:mm")}</Typography>
          );
        },
      },
    },
  ];

  function exportToExcel(data: any) {
    const indexes = data.map((item: any) => item.index);
    const personalDataColumnsName = Object.keys(
      state.orders[indexes[0]].personalData
    );
    const paymentInfoColumnsName = Object.keys(
      state.orders[indexes[0]].paymentInfo
    );

    const newColumns = [
      Vocabulary.numberOrder,
      Vocabulary.deviceName,
      Vocabulary.deviceType,
      Vocabulary.manufacturer,
      Vocabulary.model,
      Vocabulary.storage,
      Vocabulary.color,
      ...personalDataColumnsName,
      ...paymentInfoColumnsName,
      Vocabulary.estimatedPrice,
      Vocabulary.status,
      Vocabulary.paymentType,
      Vocabulary.date,
    ];
    const newData: any = [];
    for (let i = 0; i < data.length; i++) {
      const row = state.orders[i];
      const newRow = [
        row.orderNumber,
        ...Object.values(row.deviceDetails),
        ...Object.values(row.personalData),
        ...Object.values(row.paymentInfo),
        row.estimatedPrice,
        row.status.order,
        row.paymentType,
        row.createdAt,
      ];
      newData.push(newRow);
    }
    const exportData = [];
    for (let i = 0; i < newData.length; i++) {
      const raw: any = {};
      for (let k = 0; k < newColumns.length; k++) {
        raw[newColumns[k]] = newData[i][k];
      }
      exportData.push(raw);
    }
    const fileName = `Report_${moment().unix()}`;
    const ws = XLSX.utils.json_to_sheet(exportData);
    const sheetsName = ["reports"];

    const wb = { Sheets: { reports: ws }, SheetNames: sheetsName };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const orders = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(orders, fileName + fileExtension);
  }

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      viewColumns: false as any,
      selectableRows: "none" as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: false,
      filter: false,
      print: true,
      download: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        toolbar: {
          downloadCsv: Vocabulary.downloadExcel,
        },
      },
      count: state.ordersNumber,
      rowsPerPage: filters.perPage,
      page: filters.page,
      search: false,
      serverSide: true,
      sort: false,
      onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
        exportToExcel(data);
        return false;
      },
      onRowClick: (rowData: any, rowState: any) => {
        navigate(
          `${LocalUrlEnum.orders}${state.orders[rowState.rowIndex]._id}`
        );
      },
      onChangePage: (page: any) => {
        navigate(
          `${LocalUrlEnum.reports}?reports=status?page=${page + 1}?perPage=${
            filters.perPage
          }`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `${
            LocalUrlEnum.reports
          }?reports=status?page=${1}?perPage=${numberOfRows}`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  const components = {
    icons: {
      DownloadIcon: () => (
        <>
          <label style={{ fontSize: "12px", marginRight: "5px" }}>
            {Vocabulary.downloadExcel}
          </label>
          <img src={excel} alt="Excel Icon" style={{ width: 25 }} />
        </>
      ),
    },
  } as any;

  return (
    <>
      <MUIDataTable
        title={""}
        data={state.orders}
        columns={header}
        options={getTableOptions()}
        components={components}
      />
    </>
  );
}
