/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import moment from "moment";

type OrderDetailsProps = {
  order: any;
};

export default function OrderDetails(props: OrderDetailsProps) {
  const { order } = props;
  return (
    <Grid container spacing={2} style={{ fontSize: "18px", margin: 10 }}>
      <Grid item xs={6} md={3} lg={3}>
        <strong>{Vocabulary.numberOrder}:</strong>&nbsp;
        {order?.orderNumber}
      </Grid>
      <Grid item xs={6} md={3} lg={3}>
        <strong>{Vocabulary.date}:</strong>&nbsp;
        {moment(order?.createdAt).format("DD-MM-YYYY HH:mm")}
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
        lg={6}
        style={{ display: "flex", justifyContent: "flex-end" }}
      ></Grid>
    </Grid>
  );
}
