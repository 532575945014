/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import Modal from "../../Components/Modal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
type VisibilityModalProps = {
  open: boolean;
  onClose: () => void;
  log: any;
};
export default function VisibilityModal(props: VisibilityModalProps) {
  const { open, onClose, log } = props;
  const [newDiff, setNewDiff] = useState([]);
  const [oldDiff, setOldDiff] = useState([]);

  useEffect(() => {
    if (log && log.differences) {
      const oldData = [] as any;
      const newData = [] as any;
      log.differences.forEach((difference: string) => {
        const diff = difference.split("__");
        if (diff[0] === "old") oldData.push(diff[1]);
        else if (diff[0] === "new") newData.push(diff[1]);
      });
      setNewDiff(newData);
      setOldDiff(oldData);
    }
  }, [log]);
  return (
    <Modal open={open} onClose={onClose} title="Log" maxWidth={1000}>
      <Grid container spacing={4}>
        <Grid item xs={6} md={6} lg={6}>
          <p>
            {Vocabulary.userName}: {log && log.userName}
          </p>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <p>
            {Vocabulary.method}: {log && Vocabulary[log.method]}
          </p>
        </Grid>
        <Grid item xs={6} md={6} lg={6} style={{ paddingTop: 5 }}>
          <p>
            {Vocabulary.table}: {log && Vocabulary[log.table]}
          </p>
        </Grid>
        <Grid item xs={6} md={6} lg={6} style={{ paddingTop: 5 }}>
          <p>
            {Vocabulary.object}: {log && log.object}
          </p>
        </Grid>
        <Grid item xs={6} md={6} lg={6} style={{ paddingTop: 5 }}>
          <p>
            {Vocabulary.date}:{" "}
            {log && moment(log.date).format("DD-MM-YYYY HH:mm:ss")}
          </p>
        </Grid>
      </Grid>
      {log && log.differences && log.differences.length !== 0 ? (
        <>
          <h3>Diferențe</h3>
          <Grid container spacing={4}>
            <Grid item xs={6} md={6} lg={6}>
              <>
                <h4>Valori inițiale</h4>
                {oldDiff.map((d: string, key: number) => {
                  const diff = d.split(":");
                  return (
                    <p key={`${key}_olddiff`}>
                      {diff.map((value: string, key: number) => {
                        value = value.replaceAll('"', "");
                        return (
                          <span key={`${key}_oldvalue`}>
                            {Vocabulary[value] ? Vocabulary[value] : value}
                            {diff.length - 1 === key ? "" : ": "}
                          </span>
                        );
                      })}
                    </p>
                  );
                })}
              </>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <>
                <h4>Valori după modificare</h4>
                {newDiff.map((d: string, key: number) => {
                  const diff = d.split(":");
                  return (
                    <p key={`${key}_newdiff`}>
                      {diff.map((value: string, key: number) => {
                        value = value.replaceAll('"', "");
                        return (
                          <span key={`${key}_newvalue`}>
                            {Vocabulary[value] ? Vocabulary[value] : value}
                            {diff.length - 1 === key ? "" : ": "}
                          </span>
                        );
                      })}
                    </p>
                  );
                })}
              </>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Modal>
  );
}
