/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles } from "@mui/styles";
import { createStyles, styled } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from "@mui/material";
import {
  Article,
  ChevronLeft,
  Home,
  Person,
  TextSnippet,
  Description,
  DevicesOther,
} from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { CSSObject } from "@mui/system";
import { useContext, useState } from "react";
import { MenuContext } from "../Context/menuContext";
import { Vocabulary } from "../Utils/Vocabulary";
import { useNavigate } from "react-router-dom";
import { LocalUrlEnum } from "../Utils/Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SettingsIcon from "@mui/icons-material/Settings";
import theme from "../Theme/Theme";
import moment from "moment";
import { BadgeContext } from "../Context/badgeContext";

type Props = {
  classes: any;
};

const drawerWidth = 240;

/**
 *
 * @param theme
 * @returns
 */
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

/**
 *
 * @param theme
 * @returns
 */
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 30px)`,
  },
});

/**
 *
 */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: "79px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

/**
 *
 */
const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DrawerLayout(props: Props) {
  const { classes } = props;
  const badgeContext = useContext(BadgeContext);
  const menuContext = useContext(MenuContext);
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState("");

  /**
   * change the value of open collapse to the text of the button
   * @param newValue
   */
  function handleOpenCollapse(newValue: string) {
    setOpenCollapse(openCollapse === newValue ? "" : newValue);
  }
  /**
   *
   * @param path
   */
  function handleNavigationChange(path: string) {
    navigate(path);
  }

  return (
    <CustomDrawer
      variant="permanent"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.dashboard?.main,
        },
      }}
      open={menuContext.open}
    >
      <DrawerHeader>
        <IconButton
          onClick={() => {
            menuContext.setOpen(!menuContext.open);
          }}
        >
          <Avatar className={classes.avatar}>
            <ChevronLeft
              sx={{ color: theme.palette.whiteColor?.main }}
              fontSize="large"
            />
          </Avatar>
        </IconButton>
      </DrawerHeader>
      <List>
        {/* dashboard */}
        <ListItem
          className={classes.li}
          onClick={(event) => handleNavigationChange("/")}
        >
          <ListItemButton
            sx={{
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Home
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.dashboard}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* reports */}
        <ListItem
          className={classes.li}
          onClick={(event) => handleNavigationChange(`${LocalUrlEnum.reports}`)}
        >
          <ListItemButton
            sx={{
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <AnalyticsIcon
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.reports}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* orders */}
        <ListItem
          className={classes.li}
          onClick={(event) =>
            handleNavigationChange(
              `${
                LocalUrlEnum.orders
              }?page=1?perPage=20?filter=Niciun filtru?sort=createdAt,desc?startDate=${moment()
                .subtract(60, "d")
                .format("YYYY-MM-DD")}?endDate=${moment().format("YYYY-MM-DD")}`
            )
          }
        >
          <ListItemButton
            sx={{
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Description
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Badge badgeContent={badgeContext.ordersNumber} color="error">
                  <Typography className={classes.text} variant="h6">
                    {Vocabulary.orders}
                  </Typography>
                </Badge>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* devices */}
        <ListItem
          className={classes.li}
          onClick={(event) =>
            handleNavigationChange(`${LocalUrlEnum.devices}?page=1?perPage=10`)
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DevicesOther
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.devices}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* questionnaires */}
        <ListItem
          className={classes.li}
          onClick={(event) =>
            handleNavigationChange(
              `${LocalUrlEnum.questionnaires}?page=1?perPage=10`
            )
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Article
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.questionnaires}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {/* users */}
        <ListItem
          className={classes.li}
          onClick={(event) =>
            handleNavigationChange(`${LocalUrlEnum.users}?page=1?perPage=10`)
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Person
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.users}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        {/* templates */}
        <ListItem
          className={classes.li}
          sx={{ display: "block" }}
          onClick={() => handleOpenCollapse(Vocabulary.templates)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <TextSnippet
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.templates}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
            {openCollapse === Vocabulary.templates ? (
              <KeyboardArrowUpIcon
                style={{
                  fontSize: "25px",
                  margin: 10,
                  color: theme.palette.whiteColor?.main,
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{
                  fontSize: "25px",
                  margin: 10,
                  color: theme.palette.whiteColor?.main,
                }}
              />
            )}
          </ListItemButton>
          <div>
            <Collapse in={openCollapse === Vocabulary.templates} unmountOnExit>
              <List
                sx={{
                  paddingLeft: "55px",
                  color: theme.palette.whiteColor?.main,
                  background: theme.palette.dashboardDark?.main,
                  boxShadow: "inset 0 0 25px #00000033",
                }}
              >
                <ListItem
                  button
                  onClick={() =>
                    handleNavigationChange(
                      `${LocalUrlEnum.questionnairesTemplates}?page=1?perPage=10`
                    )
                  }
                >
                  <ListItemText>{Vocabulary.questionnaires}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    handleNavigationChange(
                      `${LocalUrlEnum.personalData}?page=1?perPage=10`
                    )
                  }
                >
                  <ListItemText>{Vocabulary.personalData}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    handleNavigationChange(
                      `${LocalUrlEnum.emailTemplates}?page=1?perPage=10`
                    )
                  }
                >
                  <ListItemText>{Vocabulary.email}</ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </div>
        </ListItem>
        {/* status order */}
        <ListItem
          className={classes.li}
          onClick={(event) =>
            handleNavigationChange(
              `${LocalUrlEnum.orderStatus}?page=1?perPage=10`
            )
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <BookmarkBorderIcon
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.orderStatus}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        {/* trashed orders */}
        <ListItem
          className={classes.li}
          sx={{ display: "block" }}
          onClick={() => handleOpenCollapse(Vocabulary.administration)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: menuContext.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: menuContext.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingsIcon
                sx={{ color: theme.palette.primary?.main }}
                fontSize="large"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.text} variant="h6">
                  {Vocabulary.administration}
                </Typography>
              }
              sx={{ opacity: menuContext.open ? 1 : 0 }}
            />
            {openCollapse === Vocabulary.templates ? (
              <KeyboardArrowUpIcon
                style={{
                  fontSize: "25px",
                  margin: 10,
                  color: theme.palette.whiteColor?.main,
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{
                  fontSize: "25px",
                  margin: 10,
                  color: theme.palette.whiteColor?.main,
                }}
              />
            )}
          </ListItemButton>
          <div>
            <Collapse
              in={openCollapse === Vocabulary.administration}
              unmountOnExit
            >
              <List
                sx={{
                  color: theme.palette.whiteColor?.main,
                  background: theme.palette.dashboardDark?.main,
                  boxShadow: "inset 0 0 25px #00000033",
                }}
              >
                <ListItem
                  button
                  onClick={(event) =>
                    handleNavigationChange(
                      `${LocalUrlEnum.trashedOrders}?page=1?perPage=10`
                    )
                  }
                >
                  <ListItemButton>
                    <RestoreFromTrashIcon
                      sx={{ color: theme.palette.primary?.main }}
                      fontSize="medium"
                    />
                    <ListItemText style={{ marginLeft: 10 }}>
                      {Vocabulary.trashedOrders}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
              <List
                sx={{
                  color: theme.palette.whiteColor?.main,
                  background: theme.palette.dashboardDark?.main,
                  boxShadow: "inset 0 0 25px #00000033",
                }}
              >
                <ListItem
                  button
                  onClick={(event) =>
                    handleNavigationChange(
                      `${
                        LocalUrlEnum.logs
                      }?page=1?perPage=10?startDate=${moment()
                        .subtract(14, "d")
                        .format("YYYY-MM-DD")}?endDate=${moment().format(
                        "YYYY-MM-DD"
                      )}`
                    )
                  }
                >
                  <ListItemButton>
                    <BookmarkIcon
                      sx={{ color: theme.palette.primary?.main }}
                      fontSize="medium"
                    />
                    <ListItemText style={{ marginLeft: 10 }}>
                      {Vocabulary.logs}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </div>
        </ListItem>
      </List>
    </CustomDrawer>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      [theme.breakpoints.down("lg")]: {},
    },
    avatar: {
      backgroundColor: `${theme.palette.primary?.main} !important`,
    },
    text: { color: `${theme.palette.whiteColor?.main} !important` },
    li: {
      borderBottom: `1px solid ${theme.palette.dashboardLight?.main}`,
      padding: "5px 0 5px 0 !important",
    },
  });

export default withStyles(styles, { withTheme: true })(DrawerLayout);
