import { Vocabulary } from "../../Utils/Vocabulary";

export default function NotFound() {
  return (
    <div style={{ margin: "200px auto 0 auto", width: 400 }}>
      <h1 style={{ textAlign: "center" }}>
        <big>404</big>
        <br /> {Vocabulary.pageNotFound}
        <br />
      </h1>
    </div>
  );
}
