/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
type PersonalDataDetailsProps = {
  title: string;
  details: any;
  gridSize: number;
  handleChangeValue: (key: string, value: string | any) => void;
};

export default function PersonalDataDetails(props: PersonalDataDetailsProps) {
  const { title, details, gridSize, handleChangeValue } = props;
  return (
    <Grid item xs={12} md={gridSize} lg={gridSize}>
      <div
        style={{
          padding: 15,
        }}
      >
        <h3 style={{ margin: 10 }}>{title}</h3>
        {Object.entries(details).map((detail) => (
          <div
            key={`detail_${detail[0]}`}
            style={{ margin: 10, fontSize: "15px" }}
          >
            <TextField
              type="text"
              label={Vocabulary[detail[0]] ? Vocabulary[detail[0]] : detail[0]}
              variant="standard"
              fullWidth
              value={detail[1]}
              onChange={(e) => handleChangeValue(detail[0], e.target.value)}
              style={{ margin: "10px 0px" }}
            />
          </div>
        ))}
      </div>
    </Grid>
  );
}
