/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Link, useNavigate } from "react-router-dom";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { Visibility } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import classes from "./Login.module.scss";
import { postData } from "../../Services/postData";
import theme from "../../Theme/Theme";

type Props = object;

export default function Login(props: Props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");

  /**
   *
   * @param event
   */
  function onChangeTextField(event: any) {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  }

  /**
   *
   */
  async function handleSubmit() {
    try {
      const response = await postData(UrlEnum.login, user);
      if (response.data) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("userName", response.data.userName);
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("roles", response.data.roles.join(","));
        navigate("/");
        window.location.reload();
      }
    } catch (err: any) {
      if (err.response && err.response.status === 401)
        setErrorText("Parola sau nume utilizator greșite!");
      else if (err.message) toast.error(err.message);
    }
  }

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true} />

      <div className={classes.image}>
        <img src={"/yzzy-logo.png"} alt="Yzzy" width="100%" />
      </div>
      <ValidatorForm className={classes.form} onSubmit={handleSubmit}>
        <TextValidator
          variant="filled"
          required={true}
          id="username"
          name="username"
          label={Vocabulary.email}
          validators={["required"]}
          value={user.username}
          onChange={(e) => onChangeTextField(e)}
          className={classes.textField}
          inputProps={{
            style: {
              color: theme.palette.whiteColor?.main,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.palette.whiteColor?.main,
            },
          }}
        />
        <TextValidator
          variant="filled"
          required={true}
          id="password"
          name="password"
          type={!showPassword ? "password" : "text"}
          label={Vocabulary.password}
          validators={["required"]}
          value={user.password}
          onChange={(e) => onChangeTextField(e)}
          className={classes.textField}
          inputProps={{ style: { color: theme.palette.whiteColor?.main } }}
          InputLabelProps={{ style: { color: theme.palette.whiteColor?.main } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ color: theme.palette.whiteColor?.main }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" type="submit" className={classes.button}>
          {Vocabulary.login}
        </Button>
        <Typography className={classes.errorText}>{errorText}</Typography>
      </ValidatorForm>
      <div className={classes.bottomText}>
        <Link to={LocalUrlEnum.register} className={classes.text}>
          <Typography>{Vocabulary.requireRegister}</Typography>
        </Link>
        <Link to={LocalUrlEnum.register} className={classes.text}>
          <Typography>{Vocabulary.resetPassword}</Typography>
        </Link>
      </div>
    </div>
  );
}
