/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Delete, Edit } from "@mui/icons-material";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import theme from "../../Theme/Theme";
import AddNewUser from "./AddNewUser";
import DeleteUser from "./DeleteUser";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const notifyError = (message: string) => toast.error(message);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [users, setUsers] = useState<any>([]);
  const [state, setState] = useState<any>({
    usersPerPage: [],
    page: 0,
    perPage: 10,
    order: "asc",
    filter: null,
    preview: false,
    selectedRequest: {},
    loading: false,
  });

  useEffect(() => {
    if (users.length !== 0) {
      const search = location.search.split("?");
      const newState = Object.assign({}, state) as any;
      search.forEach((value) => {
        if (value !== "") {
          const values = value.split("=");
          if (values[0] === "page") newState[values[0]] = +values[1] - 1;
          else newState[values[0]] = +values[1];
        }
      });
      let newUsers = [...users];
      newUsers = newUsers.slice(
        newState.page,
        newState.page + newState.perPage
      );
      newState.usersPerPage = newUsers;
      setState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, users]);

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs(["users"]);
    if (users.length === 0) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   */
  function getUsers() {
    setLoading(true);
    getData(UrlEnum.users)
      .then((res) => {
        setState({ ...state, usersPerPage: res.data });
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
        setLoading(false);
      });
  }

  /**
   *
   */
  const handleOpenAddUserModal = async () => {
    setOpenAddUserModal(true);
    setSelectedRow(null);
  };
  /**
   *
   * @param {*} row
   */
  const onDeleteUser = async (row: any) => {
    setDeleteId(row.id);
    setShowDeleteUserModal(true);
  };

  /**
   *
   * @param {*} row
   */
  const onEditUser = async (row: any) => {
    setSelectedRow(row);
    setOpenAddUserModal(true);
  };
  /**
   *
   * @param role
   * @returns
   */
  function getRolesName(role: string) {
    if (role === "admin") return Vocabulary.admin;
    else if (role === "user") return Vocabulary.user;
    else if (role === "serviceTechnician") return Vocabulary.serviceTechnician;
  }
  //
  const requestHeaders = [
    {
      label: Vocabulary.name.toUpperCase(),
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.email.toUpperCase(),
      name: "email",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.roles.toUpperCase(),
      name: "role",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string) => (
          <div style={{ whiteSpace: "pre" }}>{getRolesName(value)}</div>
        ),
      },
    },
    {
      label: Vocabulary.options.toUpperCase(),
      name: "optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      onEditUser(state.usersPerPage[rowIndex]);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      onDeleteUser(state.usersPerPage[rowIndex]);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      rowsPerPage: state.perPage,
      count: users.length,
      page: state.page,
      search: true,
      serverSide: true,
      sort: true,
      setRowProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      onRowClick: (rowData: any, rowState: any) => {
        onEditUser(state.usersPerPage[rowState.rowIndex]);
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page });
        navigate(
          `${LocalUrlEnum.users}?page=${page + 1}?perPage=${state.perPage}`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        navigate(`${LocalUrlEnum.users}?page=${1}?perPage=${numberOfRows}`);
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          const newUsers = users.filter((user: any) =>
            user.name.toUpperCase().includes(search.toUpperCase())
          );
          setState({ ...state, usersPerPage: newUsers });
        } else {
          await getUsers();
        }
      },
      onSearchClose: async () => {
        await getUsers();
      },
    };
  }

  return (
    <>
      <ToastContainer />
      <div style={{ textAlign: "end", marginBottom: 10 }}>
        <Button
          variant="contained"
          size="large"
          style={{
            color: theme.palette.whiteColor?.main,
            backgroundColor: theme.palette.dashboard?.main,
          }}
          onClick={handleOpenAddUserModal}
        >
          {Vocabulary.newUser}
        </Button>
      </div>
      <MUIDataTable
        title={Vocabulary.users}
        data={state.usersPerPage}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      <AddNewUser
        showAddUserModal={openAddUserModal}
        handleCloseAddUserModal={() => setOpenAddUserModal(false)}
        getUsers={getUsers}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        setLoading={setLoading}
      />
      <DeleteUser
        showDeleteUserModal={showDeleteUserModal}
        setShowDeleteUserModal={setShowDeleteUserModal}
        getUsers={getUsers}
        setLoading={setLoading}
        userId={deleteId}
      />
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
