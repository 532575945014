/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import Reports from "./Reports";
import {
  ReportsTab,
  StatusEnum,
  UrlEnum,
  localStatuses,
  paymentTypeEnum,
} from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Filters from "./Filters";
import style from "./filters.module.css";
import { getData } from "../../Services/getData";
import ReportsStatistically from "./ReportsStatistically";

export default function ReportsMainPage() {
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState<any>([]);
  const [
    ordersNumberForStatusAndPaymentType,
    setOrdersNumberForStatusAndPaymentType,
  ] = useState<any>({
    ordersNumberForEachStatus: [],
    ordersNumberForEachPaymentType: [],
  });
  const [filters, setFilters] = useState<any>({
    page: 0,
    perPage: 10,
    status: null,
    paymentType: null,
    reports: ReportsTab.status,
    startDate: moment().subtract(60, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [state, setState] = useState<any>({
    orders: [],
    ordersNumber: 0,
  });

  /**
   *
   */
  useEffect(() => {
    getData(UrlEnum.orderStatus)
      .then((res) => {
        const response = [...res.data, ...localStatuses];
        setStatuses(response);
      })
      .catch((err) => {
        //
      });
  }, []);

  /**
   *
   */
  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs(["reports"]);
    const search = location.search.split("?");
    const newFilters = Object.assign({}, filters) as any;
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else if (values[0] === "status")
          newFilters[values[0]] = decodeURI(values[1]);
        else
          newFilters[values[0]] = isNaN(+values[1])
            ? values[1] === "null"
              ? null
              : values[1]
            : +values[1];
      }
    });
    getOrders(newFilters);
  }, [location]);

  /**
   *
   */
  async function getOrders(newFilters: any) {
    let url = `${UrlEnum.reports}/${newFilters.page}/${newFilters.perPage}/${newFilters.status}/${newFilters.paymentType}/${newFilters.startDate}/${newFilters.endDate}`;
    let response = await getData(url);
    if (response && response.data) {
      setState({
        ...state,
        orders: response.data.orders,
        ordersNumber: response.data.ordersNumber,
      });
      setFilters(newFilters);
    }
    url = `${UrlEnum.reports}/${newFilters.startDate}/${newFilters.endDate}/${newFilters.status}/${newFilters.paymentType}`;
    response = await getData(url);
    if (response && response.data) {
      setOrdersNumberForStatusAndPaymentTypeProcessed(response.data);
    }
  }

  /**
   *
   * @param data
   */
  function setOrdersNumberForStatusAndPaymentTypeProcessed(data: any) {
    let ordersNumberForEachStatus = data.ordersNumberForEachStatus.filter(
      (status: any) => status.status !== StatusEnum.estimated
    );
    if (ordersNumberForEachStatus.length === 0)
      ordersNumberForEachStatus = [...data.ordersNumberForEachStatus];
    const estimated = data.ordersNumberForEachStatus.filter(
      (status: any) => status.status === StatusEnum.estimated
    );
    let ordersNumberForEachPaymentType = [
      ...data.ordersNumberForEachPaymentType,
    ];
    if (estimated.length !== 0)
      ordersNumberForEachPaymentType = [
        ...data.ordersNumberForEachPaymentType.map((item: any) => {
          if (item.paymentType === paymentTypeEnum.normal)
            return { ...item, count: item.count - estimated[0].count };
          return item;
        }),
        { paymentType: estimated[0].status, count: estimated[0].count },
      ];

    setOrdersNumberForStatusAndPaymentType({
      ordersNumberForEachStatus: ordersNumberForEachStatus,
      ordersNumberForEachPaymentType: ordersNumberForEachPaymentType,
    });
  }
  /**
   *
   * @param e
   */
  function handleChangeStartDate(e: any) {
    const date = moment(e._d).format("YYYY-MM-DD");
    navigate(
      `?reports=${
        filters.reports
      }?page=${1}?perPage=${10}?startDate=${date}?endDate=${
        filters.endDate
      }?status=${filters.status}?paymentType=${filters.paymentType}`
    );
  }

  /**
   *
   * @param e
   */
  function handleChangeEndDate(e: any) {
    const date = moment(e._d).format("YYYY-MM-DD");
    navigate(
      `?reports=${filters.reports}?page=${1}?perPage=${10}?startDate=${
        filters.startDate
      }?endDate=${date}?status=${filters.status}?paymentType=${
        filters.paymentType
      }`
    );
  }

  /**
   *
   * @param e
   * @param value
   * @param type
   */
  function handleChangeAutocomplete(e: any, value: any, type: any) {
    switch (type) {
      case ReportsTab.status:
        navigate(
          `?reports=${filters.reports}?page=${1}?perPage=${10}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?status=${
            value ? value.name : null
          }?paymentType=${filters.paymentType}`
        );
        break;
      case ReportsTab.paymentType:
        navigate(
          `?reports=${filters.reports}?page=${1}?perPage=${10}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?status=${filters.status}?paymentType=${
            value ? value : null
          }`
        );
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={style.reportsSlider}>
          <Filters
            handleChangeAutocomplete={handleChangeAutocomplete}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            data={filters}
            statuses={statuses}
          ></Filters>
        </div>
        <div className={style.mainTable} style={{ padding: "0px 20px" }}>
          <div style={{ paddingBottom: 10 }}>
            <ReportsStatistically
              ordersNumberForStatusAndPaymentType={
                ordersNumberForStatusAndPaymentType
              }
              totalOrders={state.ordersNumber}
            />
            <Reports state={state} filters={filters} />
          </div>
        </div>
      </div>
    </div>
  );
}
