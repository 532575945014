import axios from "axios";

const token = localStorage.getItem("access_token");
let axiosConfig = {};
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const request = axios.create(axiosConfig);

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  }
);
export default request;
