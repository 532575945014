import { Button } from "@mui/material";
import Modal from "../../Components/Modal";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";

type DeleteQuestionnaireModalProps = {
  openDeleteModal: boolean;
  title: string;
  setOpenDeleteModal: (openDeleteModal: boolean) => void;
  handleDelete: () => void;
};
export default function DeleteQuestionnaireModal(
  props: DeleteQuestionnaireModalProps
) {
  return (
    <Modal
      open={props.openDeleteModal}
      onClose={() => props.setOpenDeleteModal(false)}
      title={""}
    >
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "20px" }}>{props.title}</p>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: 20, color: theme.palette.whiteColor?.main }}
          size="large"
          onClick={() => props.handleDelete()}
        >
          {Vocabulary.yes}
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{
            margin: 20,
            color: theme.palette.whiteColor?.main,
            backgroundColor: theme.palette.errorColor?.main,
          }}
          size="large"
          onClick={() => props.setOpenDeleteModal(false)}
        >
          {Vocabulary.no}
        </Button>
      </div>
    </Modal>
  );
}
