/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../Components/Header";
import DrawerLayout from "../../Components/DrawerLayout";
import { Box } from "@mui/system";
import { CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LocalUrlEnum } from "../../Utils/Utils";
import style from "./Dashboard.module.scss";
type Props = {
  children?: any;
};

function Dashboard(props: Props) {
  const navigate = useNavigate();

  /**
   *
   */
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      navigate(LocalUrlEnum.login);
    }
  }, []);

  return (
    <div>
      <Header />
      <Box className={style.dashboardBox}>
        <CssBaseline />
        <DrawerLayout />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          className={style.dashboardContent}
        >
          {props.children ? props.children : <div />}
        </Box>
      </Box>
    </div>
  );
}

export default Dashboard;
