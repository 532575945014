/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import Modal from "../../Components/Modal";
import {
  QuestionnaireState,
  QuestionsContext,
} from "../../Context/questionsContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddAndShowImages from "../Devices/AddAndShowImages";

type AddImagesProps = {
  openModal: boolean;
  handleCloseModal: () => void;
  question: QuestionnaireState;
};

export default function AddImages(props: AddImagesProps) {
  const { question, openModal, handleCloseModal } = props;
  const [showDropzone, setShowDropzone] = useState(true);
  const [dropzoneFiles, setDropzoneFiles] = useState<Array<any>>([]);
  const questionsContext = useContext(QuestionsContext);

  useEffect(() => {
    if (question.images && question.images.length !== 0) setShowDropzone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   */
  function handleDeleteImages(newImageList: any, newFileList: any) {
    question.images = newImageList;
    question.files = newFileList;
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param files
   */
  function handleChangeFiles(files: any) {
    question.files = files;
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   */
  function showImages() {
    if (question.images) {
      question.images = [...question.images, ...dropzoneFiles] as any;
    } else {
      question.images = [...dropzoneFiles] as any;
    }
    setShowDropzone(false);
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        title={Vocabulary.addImages}
        maxWidth={950}
      >
        <div>
          <AddAndShowImages
            state={question}
            gridSize={12}
            showDropzone={showDropzone}
            setDropzoneFiles={setDropzoneFiles}
            setShowDropzone={setShowDropzone}
            handleDeleteImages={handleDeleteImages}
            handleChangeFiles={handleChangeFiles}
            showImages={showImages}
          />
        </div>
      </Modal>
    </div>
  );
}
