/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { Divider, Grid } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import GraphicBar from "./GraphicBar";
import { titleForReportsTotal } from "../../Utils/Utils";

type ReportsStatisticallyProps = {
  ordersNumberForStatusAndPaymentType: any;
  totalOrders: number;
};
export default function ReportsStatistically(props: ReportsStatisticallyProps) {
  const { ordersNumberForStatusAndPaymentType, totalOrders } = props;
  return (
    <div style={{ margin: "10px 0px 30px 0px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <h3 style={{ marginTop: 20, fontSize: 20, padding: 0 }}>
            {Vocabulary.ordersByStatus}
          </h3>
          <GraphicBar
            data={ordersNumberForStatusAndPaymentType.ordersNumberForEachStatus}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
          <h3 style={{ marginTop: 20, fontSize: 20, padding: 0 }}>
            {Vocabulary.totalOrders}: &nbsp; {totalOrders}
          </h3>
          {ordersNumberForStatusAndPaymentType.ordersNumberForEachPaymentType.map(
            (item: any, index: number) => {
              return (
                <Grid
                  key={`${item.paymentType}_${index}`}
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div
                    style={{
                      padding: 10,
                      margin: 10,
                    }}
                  >
                    <p style={{ fontSize: 20, padding: 0, margin: 0 }}>
                      {titleForReportsTotal[item.paymentType]
                        ? titleForReportsTotal[item.paymentType]
                        : item.paymentType}
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 30,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {item.count}
                    </p>
                  </div>
                  <Divider />
                </Grid>
              );
            }
          )}
        </Grid>
      </Grid>
    </div>
  );
}
