/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import MUIDataTable from "mui-datatables";
import {
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import LogsFilters from "./LogsFilters";
import { postData } from "../../Services/postData";
import { Visibility } from "@mui/icons-material";
import VisibilityModal from "./VisibilityModal";

export default function Logs() {
  const navigate = useNavigate();
  const location = useLocation();
  const notifyError = (message: string) => toast.error(message);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [loading, setLoading] = useState(false);
  const [openVisibilityModal, setOpenVisibilityModal] = useState(false);
  const [visibilityLog, setVisibilityLog] = useState(null);
  const [filters, setFilters] = useState({
    method: "",
    table: "",
    user: "",
    startDate: moment().subtract(14, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    page: 0,
    perPage: 10,
    search: "",
  });
  const [state, setState] = useState<any>({
    logsPerPage: [],
    logsNumber: 0,
    order: "asc",
    filter: null,
    preview: false,
    selectedRequest: {},
    loading: false,
  });

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs(["logs"]);
    const search = location.search.split("?");
    const newFilters = {
      method: "",
      table: "",
      user: "",
      startDate: moment().subtract(14, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      page: 0,
      perPage: 10,
      search: "",
    } as any;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        else newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    setFilters(newFilters);
    getLogs(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   *
   */
  function getLogs(newFilters: any) {
    setLoading(true);
    postData(`${UrlEnum.logs}/filters`, newFilters)
      .then((res) => {
        setState({
          ...state,
          logsPerPage: res.data.logs,
          logsNumber: res.data.logsNumber,
        });
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
        setLoading(false);
      });
  }

  const requestHeaders = [
    {
      label: Vocabulary.userName.toUpperCase(),
      name: "userName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.method.toUpperCase(),
      name: "method",
      options: {
        sort: false,
        customBodyRender: (method: string) => (
          <Typography>{Vocabulary[method]}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.table.toUpperCase(),
      name: "table",
      options: {
        sort: false,
        customBodyRender: (table: string) => (
          <Typography>{Vocabulary[table]}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.object.toUpperCase(),
      name: "object",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.date.toUpperCase(),
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (date: any) => (
          <Typography>{moment(date).format("DD-MM-YYYY HH:mm:ss")}</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.options.toUpperCase()}`,
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.visibility}>
                  <ToggleButton
                    onClick={() => {
                      setOpenVisibilityModal(true);
                      setVisibilityLog(state.logsPerPage[rowIndex]);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Visibility />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      rowsPerPage: filters.perPage,
      count: state.logsNumber,
      page: filters.page,
      search: true,
      serverSide: true,
      sort: true,
      setRowProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      onRowClick: (rowData: any, rowState: any) => {
        setOpenVisibilityModal(true);
        setVisibilityLog(state.logsPerPage[rowState.rowIndex]);
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page });
        if (filters.search)
          navigate(
            `${LocalUrlEnum.logs}?page=${page + 1}?perPage=${
              filters.perPage
            }?startDate=${moment(filters.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(filters.endDate).format("YYYY-MM-DD")}${
              filters.user !== "" ? `?user=${filters.user}` : ""
            }${filters.method !== "" ? `?method=${filters.method}` : ""}${
              filters.table !== "" ? `?table=${filters.table}` : ""
            }`
          );
        else
          navigate(
            `${LocalUrlEnum.logs}?page=${page + 1}?perPage=${
              filters.perPage
            }?startDate=${moment(filters.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(filters.endDate).format("YYYY-MM-DD")}${
              filters.user !== "" ? `?user=${filters.user}` : ""
            }${filters.method !== "" ? `?method=${filters.method}` : ""}${
              filters.table !== "" ? `?table=${filters.table}` : ""
            }`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (filters.search)
          navigate(
            `${
              LocalUrlEnum.logs
            }?page=${1}?perPage=${numberOfRows}?startDate=${moment(
              filters.startDate
            ).format("YYYY-MM-DD")}?endDate=${moment(filters.endDate).format(
              "YYYY-MM-DD"
            )}${filters.user !== "" ? `?user=${filters.user}` : ""}${
              filters.method !== "" ? `?method=${filters.method}` : ""
            }${filters.table !== "" ? `?table=${filters.table}` : ""}?search=${
              filters.search
            }`
          );
        else
          navigate(
            `${
              LocalUrlEnum.logs
            }?page=${1}?perPage=${numberOfRows}?startDate=${moment(
              filters.startDate
            ).format("YYYY-MM-DD")}?endDate=${moment(filters.endDate).format(
              "YYYY-MM-DD"
            )}${filters.user !== "" ? `?user=${filters.user}` : ""}${
              filters.method !== "" ? `?method=${filters.method}` : ""
            }${filters.table !== "" ? `?table=${filters.table}` : ""}`
          );
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          setState({ ...state, search: search });
          navigate(
            `${LocalUrlEnum.logs}?page=${filters.page + 1}?perPage=${
              filters.perPage
            }?startDate=${moment(filters.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(filters.endDate).format("YYYY-MM-DD")}${
              filters.user !== "" ? `?user=${filters.user}` : ""
            }${filters.method !== "" ? `?method=${filters.method}` : ""}${
              filters.table !== "" ? `?table=${filters.table}` : ""
            }?search=${search}`
          );
        } else {
          navigate(
            `${LocalUrlEnum.logs}?page=${filters.page + 1}?perPage=${
              filters.perPage
            }?startDate=${moment(filters.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(filters.endDate).format("YYYY-MM-DD")}${
              filters.user !== "" ? `?user=${filters.user}` : ""
            }${filters.method !== "" ? `?method=${filters.method}` : ""}${
              filters.table !== "" ? `?table=${filters.table}` : ""
            }`
          );
          setState({ ...state, search: null });
        }
      },
      onSearchClose: async () => {
        navigate(
          `${LocalUrlEnum.logs}?page=${filters.page + 1}?perPage=${
            filters.perPage
          }?startDate=${moment(filters.startDate).format(
            "YYYY-MM-DD"
          )}?endDate=${moment(filters.endDate).format("YYYY-MM-DD")}${
            filters.user !== "" ? `?user=${filters.user}` : ""
          }${filters.method !== "" ? `?method=${filters.method}` : ""}${
            filters.table !== "" ? `?table=${filters.table}` : ""
          }`
        );
        setState({ ...state, search: null });
      },
    };
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeAutocomplete(event: any, newValue: any, name: string) {
    let loc = location.search;
    let newLocation = loc.split("?");
    if (loc.includes(name)) {
      newLocation = newLocation.map((l: string) => {
        if (l.includes(name)) {
          if (newValue) {
            return `${name}=${newValue.name}`;
          }
          return "";
        }
        return l;
      });
      newLocation = newLocation.filter((l) => l !== "");
      loc = newLocation.join("?");
      loc = `?${loc}`;
    } else {
      if (newValue) loc += `?${name}=${newValue.name}`;
    }
    navigate(loc);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeStartDate(event: any) {
    const date = moment(filters.endDate, "YYYY-MM-DD").add(1, "days");
    if (moment(event).isAfter(date)) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de start după data de stop."
      );
      return;
    }
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("startDate")) {
        return `startDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeEndDate(event: any) {
    if (moment(event).isAfter(moment())) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de stop după data actuală."
      );
      return;
    }
    if (moment(event).isBefore(filters.startDate)) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de stop înaintea datei de start."
      );
      return;
    }
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("endDate")) {
        return `endDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <LogsFilters
        filters={filters}
        handleChangeAutocomplete={handleChangeAutocomplete}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
      />
      <MUIDataTable
        title={Vocabulary.logs}
        data={state.logsPerPage}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      <VisibilityModal
        open={openVisibilityModal}
        onClose={() => {
          setOpenVisibilityModal(false);
          setVisibilityLog(null);
        }}
        log={visibilityLog}
      />
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
