/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponsiveBar } from "@nivo/bar";

type GraphicBarProps = {
  data: any;
};
export default function GraphicBar(props: GraphicBarProps) {
  const { data } = props;
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        marginTop: 20,
        textAlign: "center",
      }}
    >
      <ResponsiveBar
        data={data}
        groupMode="grouped"
        margin={{ top: 10, right: 100, bottom: 200, left: 80 }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "gray",
              },
            },
            legend: {
              text: {
                fill: "gray",
              },
            },
            ticks: {
              line: {
                stroke: "gray",
                strokeWidth: 1,
              },
              text: {
                fill: "gray",
              },
            },
          },
          legends: {
            text: {
              fill: "gray",
            },
          },
        }}
        keys={["count"]}
        indexBy="status"
        padding={0.3}
        valueScale={{ type: "linear" }} //"symlog" -- "linear"
        indexScale={{ type: "band", round: true }}
        colors={(bar) => bar.data.color.toString()}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 60,
          legend: "Statusuri",
          legendPosition: "middle",
          legendOffset: 180,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Total comenzi",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
      />
    </div>
  );
}
