/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  CircularProgress,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import Modal from "../../Components/Modal";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";
import SaveIcon from "@mui/icons-material/Save";
import { SketchPicker } from "react-color";
import { useEffect, useState } from "react";
import { UrlEnum } from "../../Utils/Utils";
import { postData } from "../../Services/postData";
import { toast } from "react-toastify";

type AddOrderStatusModalProps = {
  openAddOrderStatusModal: boolean;
  handleChangeOrderStatusModalValue: () => void;
  selectedRow: any;
  sortNumber: number;
  getOrderStatus: () => void;
};
export default function AddOrderStatusModal(props: AddOrderStatusModalProps) {
  const {
    openAddOrderStatusModal,
    selectedRow,
    handleChangeOrderStatusModalValue,
    getOrderStatus,
    sortNumber,
  } = props;
  const [orderStatus, setOrderStatus] = useState({
    name: "",
    color: "#FFF",
  });
  const [loading, setLoading] = useState(false);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  /**
   *
   */
  useEffect(() => {
    if (selectedRow) setOrderStatus(selectedRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  /**
   *
   */
  function saveOrderStatus() {
    let url = UrlEnum.orderStatus;
    const newOrderStatus = Object.assign({}, orderStatus) as any;
    if (selectedRow) url += `/${selectedRow._id}`;
    if (!selectedRow) newOrderStatus.sortNumber = sortNumber + 1;
    newOrderStatus.userName = userName;
    postData(url, newOrderStatus)
      .then((res) => {
        onClose();
        getOrderStatus();
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  /**
   *
   */
  function onClose() {
    handleChangeOrderStatusModalValue();
    setOrderStatus({
      name: "",
      color: "#FFF",
    });
  }
  return (
    <>
      <Modal
        open={openAddOrderStatusModal}
        onClose={onClose}
        title={
          selectedRow ? Vocabulary.editOrderStatus : Vocabulary.addOrderStatus
        }
      >
        <div style={{ padding: 30 }}>
          <TextField
            type="text"
            label={Vocabulary.status}
            variant="standard"
            fullWidth
            style={{ margin: "10px 0px" }}
            value={orderStatus.name}
            onChange={(event: any) =>
              setOrderStatus({ ...orderStatus, name: event.target.value })
            }
          />
          <InputLabel style={{ margin: "10px 0px", fontSize: 15 }}>
            Alegeți culoarea pentru status
          </InputLabel>
          <div
            style={{
              display: "flex",
            }}
          >
            <SketchPicker
              color={orderStatus.color}
              onChangeComplete={(color: any) =>
                setOrderStatus({ ...orderStatus, color: color.hex })
              }
            />
            <Paper
              style={{
                width: 50,
                height: 50,
                margin: "0px 30px",
                backgroundColor: orderStatus.color,
              }}
            ></Paper>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            variant="contained"
            sx={{ color: theme.palette.whiteColor?.main }}
            color="primary"
            onClick={saveOrderStatus}
          >
            <SaveIcon /> &nbsp;&nbsp;{Vocabulary.save}
          </Button>
        </div>
      </Modal>
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
