/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import Modal from "../../Components/Modal";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";

type AddToTemplateModalProps = {
  openAddTemplate: boolean;
  handleChangeOpenAddTemplate: (() => void) | any;
  questionnaireName: string | undefined;
  saveAsTemplate: ((templateName: string) => void) | any;
};
export default function AddToTemplateModal(props: AddToTemplateModalProps) {
  const [templateName, setTemplateName] = useState(props.questionnaireName);
  return (
    <Modal
      open={props.openAddTemplate}
      onClose={props.handleChangeOpenAddTemplate}
      title={""}
    >
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "20px" }}>{Vocabulary.saveAsTemplateText}</p>
        <TextField
          type="text"
          name="templateName"
          label={Vocabulary.templateName}
          variant="standard"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          required
          style={{ width: "80%" }}
        />
        <div>
          <Button
            variant="contained"
            type="submit"
            style={{ margin: 20, color: theme.palette.whiteColor?.main }}
            size="large"
            onClick={() => props.saveAsTemplate(templateName)}
          >
            {Vocabulary.yes}
          </Button>
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: 20,
              color: theme.palette.whiteColor?.main,
              backgroundColor: theme.palette.errorColor?.main,
            }}
            size="large"
            onClick={props.handleChangeOpenAddTemplate}
          >
            {Vocabulary.no}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
