/* eslint-disable @typescript-eslint/no-explicit-any */
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { UrlEnum } from "../../Utils/Utils";
import { getData } from "../../Services/getData";

const methods = [
  { name: "insert", label: Vocabulary["insert"] },
  { name: "insertMany", label: Vocabulary["insertMany"] },
  { name: "updateMany", label: Vocabulary["updateMany"] },
  { name: "update", label: Vocabulary["update"] },
  { name: "recover", label: Vocabulary["recover"] },
  { name: "softDelete", label: Vocabulary["softDelete"] },
  { name: "delete", label: Vocabulary["delete"] },
];

const tables = [
  { name: "devices", label: Vocabulary["devices"] },
  { name: "emailTemplates", label: Vocabulary["emailTemplates"] },
  { name: "orders", label: Vocabulary["orders"] },
  { name: "orderStatus", label: Vocabulary["orderStatus"] },
  { name: "personalDataTemplates", label: Vocabulary["personalDataTemplates"] },
  { name: "questionnaires", label: Vocabulary["questionnaires"] },
  {
    name: "questionnaireTemplates",
    label: Vocabulary["questionnaireTemplates"],
  },
  { name: "users", label: Vocabulary["users"] },
];

type LogsFiltersProps = {
  filters: any;
  handleChangeAutocomplete: (event: any, newValue: any, name: string) => void;
  handleChangeStartDate: (event: any) => void;
  handleChangeEndDate: (event: any) => void;
};
export default function LogsFilters(props: LogsFiltersProps) {
  const {
    filters,
    handleChangeAutocomplete,
    handleChangeStartDate,
    handleChangeEndDate,
  } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (users.length === 0) {
      getData(UrlEnum.users)
        .then((res) => {
          const data = [] as any;
          res.data.forEach((user: any) => {
            data.push({ label: user.name, name: user.name });
          });
          setUsers(data);
        })
        .catch((err) => {
          //
        });
    }
  }, []);
  return (
    <Grid container spacing={6} style={{ marginBottom: 20 }}>
      <Grid item xs={6} md={6} lg={3} style={{ maxWidth: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Data de start"
            inputFormat="dd-MM-yyyy"
            value={filters.startDate}
            onChange={handleChangeStartDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Data de stop"
            inputFormat="dd-MM-yyyy"
            value={filters.endDate}
            onChange={handleChangeEndDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Autocomplete
          options={users}
          value={users.find((user: any) => {
            if (filters.user === user.name) return user.label;
            return "";
          })}
          freeSolo={false}
          selectOnFocus
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField {...params} label={Vocabulary.user} variant="standard" />
          )}
          onChange={(event, newValue) =>
            handleChangeAutocomplete(event, newValue, "user")
          }
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Autocomplete
          options={methods}
          value={
            Vocabulary[`${filters.method}`]
              ? Vocabulary[`${filters.method}`]
              : ""
          }
          freeSolo={false}
          selectOnFocus
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField
              {...params}
              label={Vocabulary.method}
              variant="standard"
            />
          )}
          onChange={(event, newValue) =>
            handleChangeAutocomplete(event, newValue, "method")
          }
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Autocomplete
          options={tables}
          value={
            Vocabulary[`${filters.table}`] ? Vocabulary[`${filters.table}`] : ""
          }
          freeSolo={false}
          selectOnFocus
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField
              {...params}
              label={Vocabulary.table}
              variant="standard"
            />
          )}
          onChange={(event, newValue) =>
            handleChangeAutocomplete(event, newValue, "table")
          }
        />
      </Grid>
    </Grid>
  );
}
