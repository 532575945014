/* eslint-disable react/no-array-index-key */
import { Grid, Paper } from "@mui/material";
import document from "../../assets/icons/documents.png";
import excel from "../../assets/icons/excel.png";
import pdf from "../../assets/icons/pdf.png";
import picture from "../../assets/icons/picture.png";
import word from "../../assets/icons/word.png";
import zip from "../../assets/icons/zip-file.png";
import { UrlEnum, download } from "../../Utils/Utils";
type ShowDocumentsProps = {
  documents: Array<string>;
};
export default function ShowDocuments(props: ShowDocumentsProps) {
  const { documents } = props;

  /**
   *
   * @param ext
   * @returns
   */
  function getIcon(ext: string) {
    switch (ext) {
      case "doc":
      case "docx":
        return <img src={word} alt="word" style={{ width: 60 }} />;
      case "xls":
      case "xlsx":
        return <img src={excel} alt="excel" style={{ width: 60 }} />;
      case "pdf":
        return <img src={pdf} alt="pdf" style={{ width: 60 }} />;
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        return <img src={picture} alt="images" style={{ width: 60 }} />;
      case "zip":
      case "zipx":
      case "7z":
      case "gz":
      case "rar":
        return <img src={document} alt="documents" style={{ width: 60 }} />;

      default:
        return <img src={zip} alt="archive" style={{ width: 60 }} />;
    }
  }

  function downloadFile(fileName: string) {
    const token = localStorage.getItem("access_token");
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(`${UrlEnum.orders}/getDocuments/${fileName}`, options)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob: Blob) => {
            download(blob, fileName);
          });
        }
      })
      .catch((err) => {
        //err
      });
  }
  return (
    <Paper style={{ padding: 15, margin: "0px 10px 80px 10px" }}>
      <h3>Documente</h3>
      <Grid container spacing={4} style={{ marginBottom: 40 }}>
        {documents.map((doc: string, index: number) => {
          const ext = doc.split(".");
          return (
            <Grid
              key={`documents_${index}`}
              item
              xs={6}
              md={3}
              lg={2}
              style={{ marginTop: 10 }}
            >
              <div
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => downloadFile(doc)}
              >
                {getIcon(ext[ext.length - 1])}
                <p style={{ overflowWrap: "break-word" }}>{doc}</p>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
}
