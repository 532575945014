/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Grid, Paper, TextField } from "@mui/material";
import { DeviceConfigState } from "./Device";

type DeviceColorsProps = {
  config: DeviceConfigState;
  handleChangePrice: (
    event: React.MouseEvent | any,
    config: DeviceConfigState
  ) => void;
  handleChangeColorName: (
    event: React.MouseEvent | any,
    config: DeviceConfigState
  ) => void;
};

export default function DeviceColors(props: DeviceColorsProps) {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper>
        <h2 style={{ padding: "20px 0px 0px 15px" }}>{props.config.storage}</h2>
        {props.config.colors.map((color: any, index: number) => {
          return (
            <Grid
              key={`color_${index}`}
              container
              spacing={3}
              style={{ padding: 10 }}
            >
              <Grid
                item
                xs={6}
                md={5}
                lg={5}
                style={{
                  paddingTop: 30,
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  color="primary"
                  name={`${index}`}
                  checked={!isNaN(parseFloat(color.price))}
                  onClick={(e) => props.handleChangePrice(e, props.config)}
                />
                {index === 0 ? (
                  <p>{color.color}</p>
                ) : (
                  <TextField
                    type="text"
                    name={`${index}`}
                    variant="standard"
                    fullWidth
                    value={color.color}
                    onChange={(e) =>
                      props.handleChangeColorName(e, props.config)
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  type="number"
                  name={`${index}`}
                  label="Pret"
                  variant="standard"
                  fullWidth
                  value={color.price}
                  onChange={(e) => props.handleChangePrice(e, props.config)}
                />
              </Grid>
            </Grid>
          );
        })}
      </Paper>
    </Grid>
  );
}
