/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";
export type AnswerState = {
  option: string;
  coefficientType: string; // % or LEI
  coefficientValue: number;
  hardStop: boolean;
  questions: Array<QuestionnaireState>;
};

export type QuestionnaireState = {
  question: string;
  answerType: string; //single or multiple
  answers: Array<AnswerState>;
  images: Array<string>;
  files: Array<any>;
  openCollapse: boolean;
};

export const QuestionsContext = createContext({
  questions: Array<QuestionnaireState>(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setQuestions: (questions: Array<QuestionnaireState>) => {},
});

export const QuestionsContextProvider = (props: any) => {
  const setQuestions = (questions: Array<QuestionnaireState>) => {
    setState({ ...state, questions: questions });
  };
  const initState = {
    questions: new Array<QuestionnaireState>(),
    setQuestions: setQuestions,
  };
  const [state, setState] = useState(initState);
  return (
    <QuestionsContext.Provider value={state}>
      {props.children}
    </QuestionsContext.Provider>
  );
};
