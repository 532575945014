/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

type EmailTemplateDetailsProps = {
  fromOrder: boolean;
  emailTemplate: any;
  statusEnum?: any;
  handleChangeTemplateProps: (e: any) => void;
  handleChangeTemplateStatus?: any;
  handleChangeOnFocus: (value: string) => void;
};
export default function EmailTemplateDetails(props: EmailTemplateDetailsProps) {
  const {
    fromOrder,
    emailTemplate,
    statusEnum,
    handleChangeTemplateProps,
    handleChangeTemplateStatus,
    handleChangeOnFocus,
  } = props;

  return (
    <Grid container spacing={3}>
      {fromOrder ? (
        ""
      ) : (
        <>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              type="text"
              label={Vocabulary.emailTemplateName}
              name="emailTemplateName"
              variant="standard"
              fullWidth
              required
              value={emailTemplate.emailTemplateName}
              onChange={(e: any) => handleChangeTemplateProps(e)}
              style={{ margin: "10px 0px" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl
              variant="standard"
              fullWidth
              style={{ margin: "10px 0px" }}
            >
              <InputLabel>{Vocabulary.status}</InputLabel>
              <Select
                value={emailTemplate.status}
                name="template"
                onChange={(e) => {
                  handleChangeTemplateStatus(e);
                }}
              >
                {statusEnum.map((status: any) => (
                  <MenuItem
                    key={`statusEnum_${status.name}`}
                    value={status._id}
                  >
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          type="text"
          label={Vocabulary.subject}
          name="subject"
          variant="standard"
          fullWidth
          value={emailTemplate.subject}
          onFocus={() => handleChangeOnFocus(Vocabulary.subject)}
          onChange={(e: any) => handleChangeTemplateProps(e)}
          style={{ margin: "10px 0px" }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          type="text"
          label={Vocabulary.cc}
          placeholder={
            "Introduceti adresele email-urilor separate prin virgulă"
          }
          name="cc"
          variant="standard"
          fullWidth
          value={emailTemplate.cc}
          onChange={(e: any) => handleChangeTemplateProps(e)}
          style={{ margin: "10px 0px" }}
        />
      </Grid>
    </Grid>
  );
}
