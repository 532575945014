/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestoreIcon from "@mui/icons-material/Restore";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import moment from "moment";
import { updateData } from "../../Services/updateData";
import OrderModal from "./OrderModal";
import { Delete } from "@mui/icons-material";
import { deleteData } from "../../Services/deleteData";

export default function TrashedOrders() {
  const navigate = useNavigate();
  const location = useLocation();
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [restoreTrashedOrdersModal, setRestoreTrashedOrdersModal] =
    useState(false);
  const [restoreTrashedOrders, setRestoreTrashedOrders] = useState([]);
  const [trashedOrderId, setTrashedOrderId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<any>({
    trashedOrders: [],
    trashedOrdersNumber: 0,
    personalDataKeys: [],
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
    preview: false,
    selectedRequest: {},
    loading: false,
  });
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs(["trashedOrders"]);
    const search = location.search.split("?");
    const newState = Object.assign({}, state);
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getTrashedOrders(newState.page, newState.perPage, newState.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   *
   */
  async function getTrashedOrders(
    page: number,
    perPage: number,
    search?: string | null
  ) {
    setLoading(true);
    let url = `${UrlEnum.trashedOrders}/${page}/${perPage}`;
    if (search) {
      url = `${UrlEnum.trashedOrders}/${page}/${perPage}/${search}`;
    }
    await getData(url)
      .then((res) => {
        let personalDataKeys = [] as any;
        res.data.trashedOrders.forEach((value: any) => {
          personalDataKeys.push(...Object.keys(value.personalData));
        });
        personalDataKeys = [...new Set(personalDataKeys)];
        setState({
          ...state,
          personalDataKeys: personalDataKeys,
          trashedOrders: res.data.trashedOrders,
          trashedOrdersNumber: res.data.trashedOrdersNumber,
          page: page,
          perPage: perPage,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   * @param event
   * @param id
   */
  async function onChangeRestoreTrashedOrders(event: any, id: string) {
    const newRestoreTrashedOrders = restoreTrashedOrders.slice() as any;
    if (event.target.checked) newRestoreTrashedOrders.push(id);
    else {
      const index = newRestoreTrashedOrders.findIndex(
        (value: string) => value === id
      );
      if (index >= 0) {
        newRestoreTrashedOrders.splice(index, 1);
      }
    }
    setRestoreTrashedOrders(newRestoreTrashedOrders);
  }

  /**
   *
   * @param e
   */
  async function selectAllTrashedOrders(e: any) {
    if (e.target.checked) {
      const ids = state.trashedOrders.map((q: any) => q._id);
      setRestoreTrashedOrders(ids);
    } else {
      setRestoreTrashedOrders([]);
    }
  }

  /**
   *
   */
  const trashedOrdersHeaders = [
    {
      label: "",
      name: "",
      options: {
        customHeadLabelRender: () => (
          <Checkbox
            style={{ color: "#0000008a" }}
            checked={
              restoreTrashedOrders.length === state.trashedOrders.length &&
              state.trashedOrders.length !== 0
            }
            onChange={(e) => selectAllTrashedOrders(e)}
          ></Checkbox>
        ),
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
          width: 10,
        }),
        filter: false,
        sort: false,
        selectableRowsHeader: true,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <Checkbox
              style={{ color: "#0000008a" }}
              checked={
                restoreTrashedOrders.find(
                  (em: string) => em === state.trashedOrders[rowIndex]._id
                )
                  ? true
                  : false
              }
              onChange={(e) => {
                onChangeRestoreTrashedOrders(
                  e,
                  state.trashedOrders[rowIndex]._id
                );
              }}
            ></Checkbox>
          );
        },
      },
    },
    {
      label: Vocabulary.numberOrder,
      name: "orderNumber",
    },
    {
      label: Vocabulary.deviceName,
      name: "deviceDetails",
      options: {
        sort: true,
        customBodyRender: (deviceDetails: any) => (
          <Typography>{deviceDetails.deviceName}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: true,
        customBodyRender: (status: any) => (
          <Typography>{status.order}</Typography>
        ),
      },
    },
    {
      label: Vocabulary.paymentType,
      name: "paymentType",
    },
    ...state.personalDataKeys.map((key: string) => ({
      label: key.toUpperCase(),
      name: "personalData",
      options: {
        sort: false,
        customBodyRender: (value: any) => <Typography>{value[key]}</Typography>,
      },
    })),
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any) => (
          <Typography>{moment(value).format("DD-MM-YYYY HH:mm")}</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.options.toUpperCase()}`,
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.restore}>
                  <ToggleButton
                    onClick={() => {
                      setTrashedOrderId(state.trashedOrders[rowIndex]._id);
                      setRestoreTrashedOrdersModal(true);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <RestoreIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(true);
                      setDeleteId(state.trashedOrders[rowIndex]._id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.trashedOrdersNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: true,
      serverSide: true,
      sort: true,
      setRowProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `${LocalUrlEnum.trashedOrders}?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `${LocalUrlEnum.trashedOrders}?page=${page + 1}?perPage=${
              state.perPage
            }`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `${
              LocalUrlEnum.trashedOrders
            }?page=${1}?perPage=${numberOfRows}?search=${state.search}`
          );
        else
          navigate(
            `${LocalUrlEnum.trashedOrders}?page=${1}?perPage=${numberOfRows}`
          );
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          setState({ ...state, search: search });
          navigate(
            `${LocalUrlEnum.trashedOrders}?page=${state.page + 1}?perPage=${
              state.perPage
            }?search=${search}`
          );
        } else {
          navigate(
            `${LocalUrlEnum.trashedOrders}?page=${state.page + 1}?perPage=${
              state.perPage
            }`
          );
          setState({ ...state, search: null });
        }
      },
      onSearchClose: async () => {
        navigate(
          `${LocalUrlEnum.trashedOrders}?page=${state.page + 1}?perPage=${
            state.perPage
          }`
        );
        setState({ ...state, search: null });
      },
    };
  }

  /**
   *
   */
  async function handleRecoverOrder(id: string) {
    const body = {
      userName: userName,
    };
    await updateData(`${UrlEnum.trashedOrders}/${id}`, body)
      .then(async (res) => {
        //
      })
      .catch((err) => {
        setLoading(false);
        setRestoreTrashedOrdersModal(false);
        notifyError(Vocabulary.saveError);
      });
  }

  /**
   *
   */
  async function handleRecoverOrderByIds() {
    setLoading(true);
    if (trashedOrderId) await handleRecoverOrder(trashedOrderId);
    else {
      for (let i = 0; i < restoreTrashedOrders.length; i++) {
        await handleRecoverOrder(restoreTrashedOrders[i]);
      }
    }
    setTrashedOrderId(null);
    setLoading(false);
    setRestoreTrashedOrdersModal(false);
    notifySuccess(Vocabulary.saveSuccess);
    getTrashedOrders(state.page, state.perPage, state.search);
  }

  /**
   *
   */
  async function handleDeleteByIds() {
    setLoading(true);
    if (deleteId) await handleDelete(deleteId);
    else {
      for (let i = 0; i < restoreTrashedOrders.length; i++) {
        await handleDelete(restoreTrashedOrders[i]);
      }
    }
    setDeleteId(null);
    setLoading(false);
    setOpenDeleteModal(false);
    notifySuccess(Vocabulary.saveSuccess);
    getTrashedOrders(state.page, state.perPage, state.search);
  }
  /**
   *
   */
  async function handleDelete(id: string) {
    await deleteData(`${UrlEnum.trashedOrders}/${id}/${userName}`)
      .then(async (res) => {
        //
      })
      .catch((err) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifyError(Vocabulary.deleteError);
      });
  }

  return (
    <>
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} lg={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setRestoreTrashedOrdersModal(true)}
            style={{ marginBottom: 10 }}
            disabled={restoreTrashedOrders.length === 0}
          >
            {Vocabulary.recoverOrders}
          </Button>
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setOpenDeleteModal(true)}
            style={{ marginBottom: 10 }}
            disabled={restoreTrashedOrders.length === 0}
          >
            Ștergere multiplă
          </Button>
        </Grid>
      </Grid>
      <MUIDataTable
        title={Vocabulary.trashedOrders}
        data={state.trashedOrders}
        columns={trashedOrdersHeaders}
        options={getTableOptions()}
      />
      <OrderModal
        title={Vocabulary.restoreTrashedOrders}
        openModal={restoreTrashedOrdersModal}
        closeModal={() => {
          setRestoreTrashedOrdersModal(false);
          setTrashedOrderId(null);
        }}
        handleModifyValues={handleRecoverOrderByIds}
      />
      <OrderModal
        title={Vocabulary.deleteOrderPermanent}
        openModal={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false);
          setDeleteId(null);
        }}
        handleModifyValues={handleDeleteByIds}
      />
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
