/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collapse, Divider, Grid, Paper } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import style from "./Orders.module.scss";

type QuestionsDetailsProps = {
  title: string;
  openCollapse: boolean;
  details: any;
  handleOpenCollapse: (openCollapse: boolean) => void;
};
export default function QuestionsDetails(props: QuestionsDetailsProps) {
  const { title, openCollapse, details, handleOpenCollapse } = props;
  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      style={{ margin: "10px 10px 40px 10px" }}
    >
      <Paper
        style={{
          padding: 15,
        }}
      >
        <div
          className={style.collapse}
          onClick={() => handleOpenCollapse(!openCollapse)}
        >
          <h3>{title}</h3>
          {openCollapse ? (
            <KeyboardArrowUpIcon className={style.arrowIcon} />
          ) : (
            <KeyboardArrowDownIcon className={style.arrowIcon} />
          )}
        </div>
        <Collapse in={openCollapse} unmountOnExit>
          {details.map((detail: any, index: number) => {
            let data = detail.questionText.replace(/[0-9]/g, "");
            data = data.replace(".\t", "");
            return (
              <div
                key={`question_${index}`}
                style={{ margin: 10, fontSize: "17px" }}
              >
                <p>
                  {index + 1}. {data}
                </p>
                {detail.answerValue.split(";").map((answer: string) => {
                  if (answer !== "")
                    return (
                      <div
                        key={`answer_${index}`}
                        className={style.checkIconDiv}
                      >
                        <CheckIcon className={style.checkIcon} />
                        <p>{answer}</p>
                      </div>
                    );
                  return "";
                })}
                <Divider />
              </div>
            );
          })}
        </Collapse>
      </Paper>
    </Grid>
  );
}
