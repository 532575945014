import { Button, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@material-ui/icons/Save";
import { useContext } from "react";
import { QuestionsContext } from "../../Context/questionsContext";
import AddToTemplateModal from "./AddToTemplateModal";
import style from "./Questionnaire.module.scss";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Theme/Theme";

type QuestionnaireMenuProps = {
  isQuestionnaireTemplate: boolean;
  openAddTemplate?: boolean | undefined;
  questionnaireName?: string | undefined;
  saveAsTemplate?: ((templateName: string) => void) | undefined;
  handleChangeOpenAddTemplate?: (() => void) | undefined;
};
export default function QuestionnaireMenu(props: QuestionnaireMenuProps) {
  const questionsContext = useContext(QuestionsContext);

  return (
    <>
      <Grid item xs={2}>
        <Paper className={style.questionnaireMenuPaper}>
          <Button
            fullWidth
            onClick={() => {
              questionsContext.setQuestions([
                ...questionsContext.questions,
                {
                  question: "",
                  answerType: "singleChoice",
                  images: [],
                  files: [],
                  answers: [
                    {
                      option: "",
                      coefficientType: "LEI",
                      coefficientValue: 0,
                      hardStop: false,
                      questions: [],
                    },
                  ],
                  openCollapse: true,
                },
              ]);
            }}
          >
            <AddIcon style={{ marginRight: 7, marginBottom: 4 }} />{" "}
            {Vocabulary.newQuestion}
          </Button>
          <div style={{ flex: 1 }}></div>
          {props.isQuestionnaireTemplate ? (
            ""
          ) : (
            <Button
              variant="outlined"
              size="large"
              fullWidth
              color="primary"
              style={{
                marginBottom: 10,
              }}
              onClick={props.handleChangeOpenAddTemplate}
            >
              {Vocabulary.saveAsTemplate}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            style={{
              color: theme.palette.whiteColor?.main,
              marginTop: 10,
            }}
            // onClick={props.saveQuestionnaires}
          >
            <SaveIcon style={{ marginRight: 15 }} />
            {Vocabulary.save}
          </Button>
        </Paper>
      </Grid>
      {props.openAddTemplate ? (
        <AddToTemplateModal
          openAddTemplate={props.openAddTemplate}
          handleChangeOpenAddTemplate={props.handleChangeOpenAddTemplate}
          questionnaireName={props.questionnaireName}
          saveAsTemplate={props.saveAsTemplate}
        />
      ) : (
        ""
      )}
    </>
  );
}
