/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import UserModal from "./UserModal";
import style from "./Users.module.scss";
import { v4 as uuidv4 } from "uuid";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { getRoles, UrlEnum } from "../../Utils/Utils";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";

const roles = [
  { id: 1, label: Vocabulary.admin },
  {
    id: 2,
    label: Vocabulary.user,
  },
  {
    id: 3,
    label: Vocabulary.serviceTechnician,
  },
];
type AddNewUserProps = {
  showAddUserModal: boolean;
  selectedRow: any;
  handleCloseAddUserModal: () => void;
  setSelectedRow: (selectedRow: any) => void;
  getUsers: () => void;
  setLoading: (loading: boolean) => void;
};
function AddNewUser(props: AddNewUserProps) {
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [user, setUser] = useState<any>({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: {
      id: 2,
      label: Vocabulary.user,
    },
    isEdit: false,
    errorText: "",
  });
  const {
    selectedRow,
    handleCloseAddUserModal,
    setSelectedRow,
    getUsers,
    showAddUserModal,
    setLoading,
  } = props;
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    if (selectedRow) {
      setUser({
        name: selectedRow.name,
        email: selectedRow.email,
        role: getRoles(selectedRow.role),
        isEdit: true,
        password: "",
        confirmPassword: "",
        errorText: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  /**
   *
   * @param {*} event
   */
  function onChangeTextField(event: any) {
    const newUser = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  }

  /**
   *
   */
  function generateCode() {
    const code = uuidv4();
    setUser({
      ...user,
      password: code.substr(0, 10),
      confirmPassword: code.substr(0, 10),
    });
  }

  /**
   *
   */
  function closeModal() {
    setUser({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: {
        id: 2,
        label: Vocabulary.user,
      },
      isEdit: false,
      errorText: "",
    });
    setSelectedRow(null);
    handleCloseAddUserModal();
  }

  /**
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleChangeRole = (event: any, newValue: any) => {
    setUser({ ...user, role: newValue });
  };

  /**
   *
   * @returns
   */
  const handleAddUser = () => {
    if (user.password !== user.confirmPassword) {
      setUser({ ...user, errorText: Vocabulary.notTheSame });
      return;
    }
    setLoading(true);
    const data = {
      name: user.name,
      email: user.email,
      roles: [getRoles(user.role.id)],
      password: user.password,
      userName: userName,
    } as any;
    if (selectedRow) {
      data.id = selectedRow.id;
      updateData(`${UrlEnum.users}/${selectedRow.id}`, data)
        .then(async () => {
          setSelectedRow(null);
          closeModal();
          await getUsers();
          setLoading(false);
          notifySuccess(Vocabulary.saveSuccess);
        })
        .catch((err) => {
          setLoading(false);
          notifyError(Vocabulary.saveError);
        });
    } else {
      postData(UrlEnum.register, data)
        .then(async () => {
          setSelectedRow(null);
          closeModal();
          await getUsers();
          setLoading(false);
          notifySuccess(Vocabulary.saveSuccess);
        })
        .catch((err) => {
          setLoading(false);
          notifyError(Vocabulary.saveError);
        });
    }
  };

  return (
    <UserModal
      open={showAddUserModal}
      handleClose={closeModal}
      title={Vocabulary.newUser}
      handleSubmit={handleAddUser}
      submitButton={Vocabulary.save}
      cancelButton={Vocabulary.cancel}
    >
      <TextValidator
        className={style.textField}
        label={Vocabulary.name}
        name="name"
        type="text"
        validators={["required"]}
        errorMessages={[Vocabulary.requiredField]}
        value={user.name}
        onChange={(e) => onChangeTextField(e)}
      />
      <TextValidator
        className={style.textField}
        label="Email"
        name="email"
        type="text"
        validators={["required", "isEmail"]}
        errorMessages={[Vocabulary.requiredField, Vocabulary.incorrectEmail]}
        value={user.email}
        onChange={(e) => onChangeTextField(e)}
      />
      <Autocomplete
        className={style.textField}
        disableClearable
        disablePortal
        id="role"
        options={roles}
        value={user.role ? user.role.label : ""}
        renderInput={(params) => (
          <TextField {...params} label={Vocabulary.roles} />
        )}
        onChange={(event, newValue) => handleChangeRole(event, newValue)}
      />

      {selectedRow ? (
        <div style={{ display: "flex" }}>
          <Checkbox
            style={{
              margin: 5,
              color: theme.palette.primary?.main,
            }}
            checked={!user.isEdit}
            onChange={() => setUser({ ...user, isEdit: !user.isEdit })}
          />
          <p style={{ margin: 15 }}>{Vocabulary.changePassword}</p>
        </div>
      ) : (
        ""
      )}
      <TextValidator
        className={style.textField}
        label={Vocabulary.password}
        name="password"
        type="text"
        validators={!user.isEdit ? ["required"] : ("" as any)}
        errorMessages={!user.isEdit ? [Vocabulary.requiredField] : ""}
        value={user.password}
        onChange={(e) => onChangeTextField(e)}
        disabled={user.isEdit}
        InputProps={{
          endAdornment: (
            <Button
              style={{
                color: theme.palette.whiteColor?.main,
                backgroundColor: theme.palette.primary?.main,
                maxWidth: 100,
                fontSize: 10,
              }}
              onClick={generateCode}
              fullWidth
              disabled={user.isEdit}
            >
              {Vocabulary.generate}
            </Button>
          ),
        }}
      />
      <TextValidator
        className={style.textField}
        label={Vocabulary.confirmPassword}
        name="confirmPassword"
        type="text"
        validators={!user.isEdit ? ["required"] : ("" as any)}
        errorMessages={!user.isEdit ? [Vocabulary.requiredField] : ""}
        value={user.confirmPassword}
        onChange={(e) => onChangeTextField(e)}
        disabled={user.isEdit}
      />
      <p style={{ margin: 10, color: theme.palette.errorColor?.main }}>
        {user.errorText}
      </p>
      <ToastContainer />
    </UserModal>
  );
}
export default AddNewUser;
