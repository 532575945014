/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Grid, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { DeviceState } from "./Device";

type DeviceStoragesProps = {
  state: DeviceState;
  addNewConfiguration: (storage: string) => void;
  removeConfiguration: (storage: string) => void;
};
export default function DeviceStorages(props: DeviceStoragesProps) {
  function onStorageChange(event: any, storage: string) {
    if (event.target.checked) {
      props.addNewConfiguration(storage);
    } else props.removeConfiguration(storage);
  }
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper>
        <h2 style={{ padding: "20px 0px 0px 15px" }}>{Vocabulary.storage}</h2>
        <Grid container spacing={3} style={{ padding: 10 }}>
          {props.state.defaultStorages.map((storage: any) => {
            return (
              <Grid key={`storage${storage}`} item xs={12} md={3} lg={3}>
                <Checkbox
                  color="primary"
                  checked={
                    props.state.configurations.findIndex(
                      (conf) =>
                        conf.storage.includes(storage) ||
                        storage.includes(conf.storage)
                    ) >= 0
                  }
                  onChange={(e) => onStorageChange(e, storage)}
                />
                {storage}
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
}
