/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Devices.module.scss";
import { DeviceState } from "./Device";
import { UrlEnum } from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import { Vocabulary } from "../../Utils/Vocabulary";

const deviceType = [
  {
    name: "Tablet",
    label: Vocabulary["Tablet"],
  },
  {
    name: "Phone",
    label: Vocabulary["Phone"],
  },
  {
    name: "Watch",
    label: Vocabulary["Watch"],
  },
];

type DeviceGeneralInformationProps = {
  state: DeviceState;
  setState: (state: DeviceState) => void;
  setDevicesResponse: (devicesResponse: Array<any>) => void;
  setOpenChooseDevicesModal: (openChooseDevicesModal: boolean) => void;
  handleChangeState: (event: React.MouseEvent | any) => void;
  setLoading: (loading: boolean) => void;
};
export default function DeviceGeneralInformation(
  props: DeviceGeneralInformationProps
) {
  const notifyError = (message: string) => toast.error(message);

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeDeviceType(event: any, newValue: any) {
    const newState = Object.assign({}, props.state) as any;
    if (newValue) newState.deviceType = newValue.name;
    else {
      newState.deviceType = "";
    }
    props.setState(newState);
  }

  /**
   *
   * @param event
   */
  function getInformationAboutDevice() {
    props.setLoading(true);
    const searchUrl = `${UrlEnum.searchGSMDevice}/${props.state.deviceName}`;
    getData(searchUrl)
      .then((response) => {
        if (response.data.length !== 0) {
          props.setDevicesResponse(response.data);
          props.setOpenChooseDevicesModal(true);
        }
        props.setLoading(false);
      })
      .catch((err) => {
        props.setLoading(false);
        notifyError(Vocabulary.noResultsFound);
      });
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <ToastContainer />
      <Autocomplete
        options={deviceType}
        value={
          Vocabulary[props.state.deviceType]
            ? Vocabulary[props.state.deviceType]
            : ""
        }
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        className={style.deviceType}
        renderInput={(params) => (
          <TextField
            {...params}
            label={Vocabulary.deviceType}
            variant="standard"
            required
          />
        )}
        onChange={(event, newValue) => handleChangeDeviceType(event, newValue)}
      />
      <TextField
        type="text"
        name="manufacturer"
        label={Vocabulary.manufacturer}
        variant="standard"
        value={props.state.manufacturer}
        onChange={(e) => props.handleChangeState(e)}
        className={style.textField}
        required
      />
      <TextField
        type="text"
        name="model"
        label={Vocabulary.model}
        variant="standard"
        value={props.state.model}
        onChange={(e) => props.handleChangeState(e)}
        className={style.textField}
        required
      />
      <div style={{ display: "flex" }}>
        <TextField
          type="text"
          name="deviceName"
          label={Vocabulary.deviceName}
          variant="standard"
          fullWidth
          value={props.state.deviceName}
          onChange={(e) => props.handleChangeState(e)}
          style={{ margin: 10 }}
          required
        />
        <Button
          variant="contained"
          className={style.searchBtn}
          onClick={getInformationAboutDevice}
        >
          {Vocabulary.search}
        </Button>
      </div>

      <TextField
        type="text"
        name="paymentMethod"
        label={Vocabulary.paymentMethod}
        variant="standard"
        value={props.state.paymentMethod}
        onChange={(e) => props.handleChangeState(e)}
        className={style.textField}
        required
      />
    </Grid>
  );
}
