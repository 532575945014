import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login/Login";
import theme from "../Theme/Theme";
import Orders from "../Pages/Orders/Orders";
import Users from "../Pages/Users/Users";
import Devices from "../Pages/Devices/Devices";
import Questionnaires from "../Pages/Questionnaire/Questionnaires";
import Home from "../Pages/Home/Home";
import Device from "../Pages/Devices/Device";
import Questionnaire from "../Pages/Questionnaire/Questionnaire";
import QuestionnairesTemplates from "../Pages/Templates/QuestionnairesTemplates";
import PersonalDataTemplates from "../Pages/Templates/PersonalDataTemplates";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { LocalUrlEnum } from "../Utils/Utils";
import NotFound from "../Pages/Common/NotFound";
import QuestionnaireTemplate from "../Pages/Templates/QuestionnaireTemplate";
import { BreadCrumbsContextProvider } from "../Context/breadCrumbsContext";
import Order from "../Pages/Orders/Order";
import EmailTemplates from "../Pages/Templates/EmailTemplates";
import OrderStatus from "../Pages/OrderStatus/OrderStatus";
import TrashedOrders from "../Pages/Orders/TrashedOrders";
import Logs from "../Pages/Logs/Logs";
import { BadgeContextProvider } from "../Context/badgeContext";
import ReportsMainPage from "../Pages/Reports/ReportsMainPage";

const Router = () => {
  function addDashboard(component: object) {
    return <Dashboard>{component}</Dashboard>;
  }

  return (
    <ThemeProvider theme={theme}>
      <BreadCrumbsContextProvider>
        <BadgeContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={addDashboard(<Home />)} />
              <Route path={LocalUrlEnum.login} element={<Login />} />
              <Route
                path={LocalUrlEnum.orders}
                element={addDashboard(<Orders />)}
              />
              <Route
                path={`${LocalUrlEnum.orders}:id`}
                element={addDashboard(<Order />)}
              />
              <Route
                path={LocalUrlEnum.devices}
                element={addDashboard(<Devices />)}
              />
              <Route
                path={`${LocalUrlEnum.devices}:id`}
                element={addDashboard(<Device />)}
              />
              <Route
                path={LocalUrlEnum.questionnaires}
                element={addDashboard(<Questionnaires />)}
              />
              <Route
                path={`${LocalUrlEnum.questionnaires}:id`}
                element={addDashboard(<Questionnaire />)}
              />
              <Route
                path={LocalUrlEnum.questionnairesTemplates}
                element={addDashboard(<QuestionnairesTemplates />)}
              />
              <Route
                path={`${LocalUrlEnum.questionnairesTemplates}:id`}
                element={addDashboard(<QuestionnaireTemplate />)}
              />
              <Route
                path={LocalUrlEnum.personalData}
                element={addDashboard(<PersonalDataTemplates />)}
              />
              <Route
                path={LocalUrlEnum.emailTemplates}
                element={addDashboard(<EmailTemplates />)}
              />
              <Route
                path={LocalUrlEnum.users}
                element={addDashboard(<Users />)}
              />
              <Route
                path={LocalUrlEnum.orderStatus}
                element={addDashboard(<OrderStatus />)}
              />
              <Route
                path={LocalUrlEnum.trashedOrders}
                element={addDashboard(<TrashedOrders />)}
              />
              <Route
                path={LocalUrlEnum.logs}
                element={addDashboard(<Logs />)}
              />
              <Route
                path={LocalUrlEnum.reports}
                element={addDashboard(<ReportsMainPage />)}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </BadgeContextProvider>
      </BreadCrumbsContextProvider>
    </ThemeProvider>
  );
};
export default Router;
