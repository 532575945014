/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DropzoneArea } from "material-ui-dropzone";
import style from "./Devices.module.scss";
import { DeviceState } from "./Device";
import { QuestionnaireState } from "../../Context/questionsContext";
import { Vocabulary } from "../../Utils/Vocabulary";

type AddAndShowImagesProps = {
  state: DeviceState | QuestionnaireState;
  showDropzone: boolean;
  gridSize: number;
  setDropzoneFiles: (images: Array<any>) => void;
  setShowDropzone: (showDropzone: boolean) => void;
  handleDeleteImages: (newImageList: any, newFileList: any) => void;
  handleChangeFiles: (files: any) => void;
  showImages: () => void;
};

export default function AddAndShowImages(props: AddAndShowImagesProps) {
  const {
    state,
    showDropzone,
    gridSize,
    setDropzoneFiles,
    setShowDropzone,
    handleDeleteImages,
    handleChangeFiles,
    showImages,
  } = props;
  /**
   *
   * @param files
   */
  function handleChangeDropFile(files: any) {
    if (files.length !== 0) {
      handleChangeFiles(files);
      const images = [] as any;
      files.forEach((file: any) => {
        const reader = new FileReader();
        if (file.type.match("image.*")) {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            images.push({
              path: reader.result,
              name: file.name,
            });
            setDropzoneFiles(images);
          };
        }
      });
    }
  }

  /**
   *
   * @param index
   */
  function deleteImage(index: any) {
    const newImageList = state.images.slice() as any;
    let newFileList = [];
    if (state.files) {
      newFileList = state.files.slice() as any;
      const fileIndex = newFileList.findIndex(
        (file: any) => file.name === newImageList[index].name
      );
      if (fileIndex >= 0) newFileList.splice(fileIndex, 1);
    }
    newImageList.splice(index, 1);
    handleDeleteImages(newImageList, newFileList);
  }

  return (
    <Grid item xs={12} md={gridSize} lg={gridSize}>
      {showDropzone ? (
        <div>
          <DropzoneArea
            acceptedFiles={["image/*"]}
            dropzoneClass={style.dropzoneArea}
            maxFileSize={30000000} //30 MB
            filesLimit={10}
            onChange={handleChangeDropFile}
          />
          <Button
            variant="contained"
            size="large"
            className={style.showImagesBtn}
            onClick={showImages}
          >
            {Vocabulary.finish}
          </Button>
        </div>
      ) : (
        <div>
          <div className={style.displayImagesContainer}>
            {state.images.map((image: any, index: any) => {
              return (
                <div key={`image_${index}`} className={style.displayImages}>
                  <IconButton
                    className={style.deleteImagesBtn}
                    onClick={() => deleteImage(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <img
                    src={image.path ? image.path : image}
                    alt="preview"
                    style={{ height: "100%" }}
                  />
                </div>
              );
            })}
          </div>
          <Button
            variant="contained"
            size="large"
            className={style.showImagesBtn}
            onClick={() => {
              setDropzoneFiles([]);
              setShowDropzone(true);
            }}
          >
            {Vocabulary.addNewImages}
          </Button>
        </div>
      )}
    </Grid>
  );
}
