/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles } from "@mui/styles";
import { createStyles } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  classes?: any;
  open: boolean;
  onClose: () => void;
  children: any;
  title: any;
  restModalProps?: any;
  maxWidth?: number;
};

function Modal(props: Props) {
  const { open, children, onClose, classes, title, restModalProps, maxWidth } =
    props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: maxWidth ? maxWidth : 600,
          },
        },
      }}
      id="previewDialogContent"
      {...restModalProps}
    >
      <DialogTitle className={classes.dialogTitle}>
        <p className={classes.title}>{title}</p>
        <p className={classes.close}>
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </p>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

const styles = (theme: any) =>
  createStyles({
    dialogTitle: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      margin: 10,
    },
    title: {
      margin: 10,
      fontSize: "26px",
    },
    close: {
      position: "absolute",
      right: 20,
    },
    closeIcon: {
      cursor: "pointer",
      fontSize: 28,
    },
  });
export default withStyles(styles, { withTheme: true })(Modal);
