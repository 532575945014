/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Box, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ReportsTab, europeanDate, paymentTypeEnum } from "../../Utils/Utils";
import style from "./filters.module.css";

type FiltersProps = {
  handleChangeAutocomplete: (event: any, newValue: any, name: string) => void;
  handleChangeStartDate: (event: any) => void;
  handleChangeEndDate: (event: any) => void;
  data: any;
  statuses: any;
};
const paymentType = [paymentTypeEnum.normal, paymentTypeEnum.consignment];
export default function Filters(props: FiltersProps) {
  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleChangeAutocomplete,
    data,
    statuses,
  } = props;

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.startDate}
              value={moment(data?.startDate)}
              maxDate={moment(data?.endDate)}
              onChange={handleChangeStartDate}
              inputFormat={europeanDate}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.endDate}
              value={moment(data?.endDate)}
              minDate={moment(data?.startDate)}
              onChange={handleChangeEndDate}
              inputFormat={europeanDate}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BookmarkBorderIcon
            sx={{ color: "#4d5e80", mr: 1, my: 2 }}
            fontSize="medium"
          />
          <Autocomplete
            options={statuses}
            value={{
              name:  data.status==="null" || data.status===null ? "" : data.status,
            }}
            freeSolo={false}
            selectOnFocus
            handleHomeEndKeys
            fullWidth
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.status}
                variant="standard"
                fullWidth
              />
            )}
            onChange={(event, newValue) =>
              handleChangeAutocomplete(event, newValue, ReportsTab.status)
            }
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BookmarkBorderIcon
            sx={{ color: "#4d5e80", mr: 1, my: 2 }}
            fontSize="medium"
          />
          <Autocomplete
            options={paymentType}
            value={data?.paymentType || ""}
            freeSolo={false}
            selectOnFocus
            handleHomeEndKeys
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.paymentType}
                variant="standard"
                fullWidth
              />
            )}
            onChange={(event, newValue) =>
              handleChangeAutocomplete(event, newValue, ReportsTab.paymentType)
            }
          />
        </Box>
      </div>
    </div>
  );
}
