/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { UrlEnum } from "../../Utils/Utils";
import { useLocation } from "react-router-dom";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionsDetails from "./QuestionsDetails";
import DeviceDetails from "./DeviceDetails";
import PriceAndPayment from "./PriceAndPayment";
import StatusOrder from "./StatusOrder";
import { postData } from "../../Services/postData";
import { Vocabulary } from "../../Utils/Vocabulary";
import OrderDetails from "./OrderDetails";
import theme from "../../Theme/Theme";
import SaveIcon from "@mui/icons-material/Save";
import SendEmailModal from "./SendEmailModal";
import ShowDocuments from "./ShowDocuments";
import { BadgeContext } from "../../Context/badgeContext";

const styles = {
  color: "black",
  boxShadow: "none",
  width: "calc(100% - 260px)",
};
export default function Order() {
  const location = useLocation();
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const badgeContext = useContext(BadgeContext);
  const notifyError = (message: string) => toast.error(message);
  //   const notifySuccess = (message: string) => toast.success(message);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<any>(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  /**
   *
   */
  useEffect(() => {
    let id = location.pathname;
    id = id.replace("/orders/", "");
    if (id !== "0" && !order) {
      getOrder(id);
    }
  }, []);

  /**
   *
   * @param id
   */
  function getOrder(id: string) {
    setLoading(true);
    getData(`${UrlEnum.orders}/${id}`)
      .then((res) => {
        breadCrumbsContext.setBreadCrumbs([
          "orders",
          `${res.data[0].orderNumber}`,
        ]);
        setOrder(res.data[0]);
        if (res.data[0].isNew) {
          badgeContext.setOrdersNumber(badgeContext.ordersNumber - 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   * @param value
   */
  function handleOpenCollapse(value: boolean) {
    setOpenCollapse(value);
  }

  /**
   *
   * @param e
   */
  function handleChangePrice(e: any) {
    setOrder({ ...order, estimatedPrice: e.target.value });
  }

  /**
   *
   * @param key
   * @param value
   */
  function handleChangeStatus(
    id: string | null | undefined,
    value: string | undefined
  ) {
    const newStatus = Object.assign({}, order.status);
    newStatus["order"] = value;
    order.statusId = id;
    setOrder({ ...order, status: newStatus });
  }

  /**
   *
   * @param key
   * @param value
   */
  function handleChangePersonalDataValue(key: string, value: string | any) {
    const newPersonalData = Object.assign({}, order.personalData);
    newPersonalData[key] = value;
    setOrder({ ...order, personalData: newPersonalData });
  }

  /**
   *
   * @param key
   * @param value
   */
  function handleChangePaymentInfoValue(key: string, value: string | any) {
    const newPaymentInfo = Object.assign({}, order.paymentInfo);
    newPaymentInfo[key] = value;
    setOrder({ ...order, paymentInfo: newPaymentInfo });
  }

  /**
   *
   * @param key
   * @param value
   */
  function handleChangeDeviceDetailsValue(key: string, value: string | any) {
    const newDeviceDetails = Object.assign({}, order.deviceDetails);
    newDeviceDetails[key] = value;
    setOrder({ ...order, deviceDetails: newDeviceDetails });
  }

  /**
   *
   * @param value
   */
  function handleChangePaymentType(value: string) {
    setOrder({ ...order, paymentType: value });
  }
  /**
   *
   * @param statusId
   */
  async function getEmailTemplate(statusId: string) {
    await getData(`${UrlEnum.emailTemplates}/${statusId}`)
      .then((res) => {
        setEmailTemplate(res.data[0]);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  function onSaveOrder(value: boolean, body?: any) {
    let postBody = { ...order };
    if (body) {
      postBody = { ...order, ...body };
    }
    setLoading(true);
    postBody.sendEmail = value;
    postBody.userName = userName;
    // send email and save order + documents
    if (body) {
      const formData = new FormData() as any;
      body.attachments.forEach((attachment: any) => {
        formData.append("files", attachment, attachment.name);
      });
      postData(UrlEnum.sendEmail, formData)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++)
            postBody.attachments[i] = {
              content: res.data[i],
              filename: res.data[i],
              type: postBody.attachments[i].type,
              disposition: "attachment",
            };
          if (order.documents)
            postBody.documents = [...order.documents, ...res.data];
          else postBody.documents = res.data;
          postData(`${UrlEnum.orders}/${order._id}`, postBody)
            .then((response) => {
              setLoading(false);
              window.location.reload();
            })
            .catch((error) => {
              setLoading(false);
              notifyError(Vocabulary.saveError);
            });
        })
        .catch((err) => {
          setLoading(false);
          notifyError(Vocabulary.saveError);
        });
      //save without sending email
    } else {
      postData(`${UrlEnum.orders}/${order._id}`, postBody)
        .then((response) => {
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          notifyError(Vocabulary.saveError);
        });
    }
  }

  return (
    <>
      <ToastContainer />
      {order ? (
        <StatusOrder
          isOrder={true}
          status={order.status.order}
          handleChangeStatus={handleChangeStatus}
          name="orderStatus"
          styles={styles}
        />
      ) : (
        ""
      )}
      <Grid
        id="forPrint"
        container
        spacing={2}
        style={{ marginTop: 30, marginBottom: 40 }}
      >
        <OrderDetails order={order} />
        <DeviceDetails
          details={order ? order.deviceDetails : ""}
          handleChangeDeviceDetailsValue={handleChangeDeviceDetailsValue}
        />
        <PriceAndPayment
          order={order}
          handleChangePaymentType={handleChangePaymentType}
          handleChangePrice={handleChangePrice}
          handleChangePersonalDataValue={handleChangePersonalDataValue}
          handleChangePaymentInfoValue={handleChangePaymentInfoValue}
        />
        <QuestionsDetails
          title={Vocabulary.questions}
          details={order ? order.answers : []}
          openCollapse={openCollapse}
          handleOpenCollapse={handleOpenCollapse}
        />
      </Grid>
      {order && order.documents && order.documents.length !== 0 ? (
        <ShowDocuments documents={order.documents} />
      ) : (
        ""
      )}
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          left: 240,
          width: "calc(100% - 240px)",
          textAlign: "center",
          boxShadow: "0px -2px 6px #00000033",
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{
            margin: 20,
            color: theme.palette.whiteColor?.main,
          }}
          onClick={
            order && order.statusId
              ? () => {
                  setSendEmailModal(true);
                  getEmailTemplate(order.statusId);
                }
              : () => onSaveOrder(false)
          }
        >
          <SaveIcon /> &nbsp;&nbsp; {Vocabulary.save}
        </Button>
      </div>

      <SendEmailModal
        open={sendEmailModal}
        data={emailTemplate}
        onClose={() => {
          setSendEmailModal(false);
          setEmailTemplate(null);
        }}
        handleSendEmail={(value: boolean, body?: any) => {
          onSaveOrder(value, body);
        }}
        title={""}
      />
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
