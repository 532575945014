/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import { Vocabulary } from "../../Utils/Vocabulary";

type AssociatedWithAQuestionnaireProps = {
  questionnaires: any;
  questionnaireId: string | null | undefined;
  associatedQuestionnaire: string;
  handleChangeAssociatedValue: (e: any) => void;
  editAssociatedQuestionnaire: () => void;
};
export default function AssociatedWithAQuestionnaire(
  props: AssociatedWithAQuestionnaireProps
) {
  const questionnaireIndex = props.questionnaires.findIndex(
    (q: any) => q._id === props.questionnaireId
  );
  return (
    <div style={{ paddingBottom: 5 }}>
      {props.questionnaireId ? (
        <div style={{ display: "flex" }}>
          <p>
            {Vocabulary.questionnaireName}:{" "}
            {props.questionnaires[questionnaireIndex]?.questionnaireName}
          </p>
          <EditIcon
            style={{ margin: 10, cursor: "pointer" }}
            onClick={props.editAssociatedQuestionnaire}
          />
        </div>
      ) : (
        <FormControl variant="standard" sx={{ minWidth: 200 }}>
          <InputLabel> {Vocabulary.questionnaire}</InputLabel>
          <Select
            value={props.associatedQuestionnaire}
            name="questionnaireName"
            onChange={(e) => props.handleChangeAssociatedValue(e)}
            label={Vocabulary.questionnaire}
          >
            {props.questionnaires.map((questionnaire: any) => (
              <MenuItem
                key={questionnaire._id}
                value={`${questionnaire._id}_${questionnaire.questionnaireName}`}
              >
                {questionnaire.questionnaireName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
