/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from "@mui/material";
import Modal from "../../Components/Modal";
import theme from "../../Theme/Theme";
import EmailTemplate from "../Templates/EmailTemplate";
import { useEffect, useState } from "react";
// import { Vocabulary } from "../../Utils/Vocabulary";

type SendEmailModalProps = {
  open: boolean;
  title: string;
  data: any;
  onClose: () => void;
  handleSendEmail: (value: boolean, body?: any) => void;
};

export default function SendEmailModal(props: SendEmailModalProps) {
  const { open, title, data, onClose, handleSendEmail } = props;
  const [files, setFiles] = useState<any>([]);
  const [emailTemplate, setEmailTemplate] = useState<any>(null);

  useEffect(() => {
    if (data) setEmailTemplate(data);
  }, [data]);
  /**
   *
   * @param e
   */
  function addAttachments(e: any) {
    const newFiles = e.target.files;
    if (newFiles) {
      setFiles([...files, ...newFiles]);
    }
  }

  /**
   *
   * @param emailTem
   */
  function handleChangeEmailTemplate(emailTem: any) {
    setEmailTemplate(emailTem);
  }

  function sendEmail() {
    const body = {
      cc: emailTemplate.cc,
      subject: emailTemplate.subject,
      html: emailTemplate.html,
      attachments: files,
    };
    handleSendEmail(true, body);
  }
  return (
    <Modal open={open} onClose={onClose} title={title} maxWidth={1500}>
      <div style={{ textAlign: "center" }}>
        <Typography style={{ fontSize: 25, margin: 15 }}>
          Schimbarea statusului implică trimiterea unui email către client.
        </Typography>

        <EmailTemplate
          data={data}
          onClose={onClose}
          fromOrder={true}
          handleChangeEmailTemplate={handleChangeEmailTemplate}
        />
        <div style={{ textAlign: "left", margin: 10, fontSize: 15 }}>
          <input
            type="file"
            multiple
            total-max-size="15000000"
            onChange={(e) => addAttachments(e)}
            style={{ margin: "10px 0px" }}
          />
          <div>
            Fișiere:&nbsp;&nbsp;
            {files.map((file: any, index: number) => (
              <span key={`files_${index}`}>{file.name}; &nbsp;&nbsp;</span>
            ))}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            style={{ margin: 20, color: theme.palette.whiteColor?.main }}
            size="large"
            onClick={() => sendEmail()}
          >
            Salvează și trimite mail
          </Button>
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: 20,
              color: theme.palette.whiteColor?.main,
            }}
            size="large"
            onClick={() => handleSendEmail(false)}
          >
            Salvează fără să se trimită mail
          </Button>
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: 20,
              color: theme.palette.whiteColor?.main,
              backgroundColor: theme.palette.errorColor?.main,
            }}
            size="large"
            onClick={onClose}
          >
            Anulează
          </Button>
        </div>
      </div>
    </Modal>
  );
}
