/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Modal";
import { postData } from "../../Services/postData";
import theme from "../../Theme/Theme";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

type UseTemplateModalProps = {
  openModalUseTemplate: boolean;
  handleOpenModalUseTemplate: () => void;
  addNewQuestionnaire: () => void;
  templates: Array<any>;
};

export default function UseTemplateModal(props: UseTemplateModalProps) {
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [template, setTemplate] = useState<any>({});
  const notifyError = (message: string) => toast.error(message);
  const navigate = useNavigate();

  /**
   *
   * @param e
   */
  function handleChangeTemplate(e: any) {
    const templateValue = e.target.value.split("_");
    setTemplate(props.templates[parseInt(templateValue)]);
    setQuestionnaireName(templateValue[1]);
  }

  function saveTemplateInQuestionnaire() {
    const newQuestionnaire = {
      questionnaireName: questionnaireName,
      questions: template.questions,
    };
    postData(UrlEnum.questionnaire, newQuestionnaire)
      .then((res) => {
        navigate(`${LocalUrlEnum.questionnaires}${res.data._id}`);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
      });
  }
  return (
    <Modal
      open={props.openModalUseTemplate}
      onClose={props.handleOpenModalUseTemplate}
      title={""}
    >
      <ToastContainer />
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "20px" }}>{Vocabulary.useTemplate}</p>
        <FormControl variant="standard" sx={{ minWidth: 200, width: "80%" }}>
          <InputLabel>{Vocabulary.template}</InputLabel>
          <Select
            value={template.templateName}
            name="template"
            onChange={(e) => {
              handleChangeTemplate(e);
            }}
            label={Vocabulary.template}
          >
            {props.templates.map((template: any, index: number) => (
              <MenuItem
                key={`templates${index}`}
                value={`${index}_${template.templateName}`}
              >
                {template.templateName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          type="text"
          name="questionnaireName"
          label={Vocabulary.questionnaireName}
          variant="standard"
          value={questionnaireName}
          onChange={(e) => setQuestionnaireName(e.target.value)}
          required
          style={{ width: "80%" }}
        />
        <div>
          <Button
            variant="contained"
            type="submit"
            style={{ margin: 20, color: theme.palette.whiteColor?.main }}
            size="large"
            color="primary"
            onClick={() => saveTemplateInQuestionnaire()}
          >
            {Vocabulary.yes}
          </Button>
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: 20,
              color: theme.palette.whiteColor?.main,
              backgroundColor: theme.palette.errorColor?.main,
            }}
            size="large"
            onClick={props.addNewQuestionnaire}
          >
            {Vocabulary.no}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
