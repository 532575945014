/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import AdditionalData from "./AdditionalData";
import { Button, CircularProgress } from "@mui/material";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";
import { postData } from "../../Services/postData";
import { resizeImage, UrlEnum } from "../../Utils/Utils";
import { toast } from "react-toastify";
import EmailTemplateDetails from "./EmailTemplateDetails";
import htmlToDraft from "html-to-draftjs";
import SaveIcon from "@mui/icons-material/Save";
import { getData } from "../../Services/getData";

type EmailTemplateProps = {
  data: any;
  fromOrder: boolean;
  onClose: (shouldRefetch?: boolean) => void;
  handleChangeEmailTemplate?: any;
};

export default function EmailTemplate(props: EmailTemplateProps) {
  const { data, onClose, fromOrder, handleChangeEmailTemplate } = props;
  const [loading, setLoading] = useState(false);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);
  const [emailTemplate, setEmailTemplate] = useState({
    html: "" as any,
    emailTemplateName: "",
    status: "",
    subject: "",
    cc: "",
  });
  const [emailTemplateHTML, setEmailTemplateHTML] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [lastFocused, setLastFocused] = useState(Vocabulary.subject);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    if (data && emailTemplate.emailTemplateName === "") {
      setEmailTemplate({
        ...emailTemplate,
        emailTemplateName: data.emailTemplateName,
        status: data.statusId ? data.statusId : "",
        subject: data.subject ? data.subject : "",
        cc: data.cc ? data.cc : "",
        html: getHtml(data.html),
      });
      setEmailTemplateHTML(data.html);
    } else if (!data) {
      setEmailTemplate({ ...emailTemplate, html: EditorState.createEmpty() });
    }
    getStatusEnum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   *
   */
  function getStatusEnum() {
    getData(UrlEnum.orderStatus)
      .then((res) => {
        setStatusEnum(res.data);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   * @param html
   * @returns
   */
  function getHtml(html: string) {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }
  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   * @param tag
   */
  const handleClose = (tag: any) => {
    if (tag !== "") {
      const text = `{{${tag}}}`;
      setAnchorEl(null);

      if (lastFocused === Vocabulary.subject) {
        setEmailTemplate({
          ...emailTemplate,
          subject: `${emailTemplate.subject} ${text}`,
        });
      } else {
        const editorState = emailTemplate.html;
        const contentState = editorState.getCurrentContent();

        let newContentState = contentState.createEntity(
          "unstyled",
          "IMMUTABLE",
          text
        );
        const entityKey = contentState.getLastCreatedEntityKey();
        const selectionState = emailTemplate.html.getSelection();
        newContentState = Modifier.insertText(
          newContentState,
          selectionState,
          text,
          "" as any,
          entityKey
        );
        const newHtml = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        setEmailTemplate({ ...emailTemplate, html: newHtml });
      }
    }
  };
  /**
   *
   * @param e
   */
  function onChange(e: any) {
    setEmailTemplate({
      ...emailTemplate,
      html: e,
    });
    const html = draftToHtml(convertToRaw(e.getCurrentContent()));
    setEmailTemplateHTML(html);

    if (handleChangeEmailTemplate) {
      const template = { ...emailTemplate };
      template.html = html;
      handleChangeEmailTemplate(template);
    }
  }

  /**
   *
   */
  function handleChangeTemplateProps(e: any) {
    const newEmailTemplate = Object.assign({}, emailTemplate) as any;
    newEmailTemplate[e.target.name] = e.target.value;
    setEmailTemplate(newEmailTemplate);

    if (handleChangeEmailTemplate) {
      const template = { ...newEmailTemplate };
      template.html = emailTemplateHTML;
      handleChangeEmailTemplate(template);
    }
  }

  /**
   *
   * @param e
   */
  function handleChangeTemplateStatus(e: any) {
    setEmailTemplate({
      ...emailTemplate,
      status: e.target.value,
    });
  }

  /**
   *
   * @param value
   */
  function handleChangeOnFocus(value: string) {
    setLastFocused(value);
  }
  /**
   *
   */
  function handleSubmit() {
    if (emailTemplate.status === "") {
      notifyError(Vocabulary.emailError);
      return;
    }
    const body = {
      html: emailTemplateHTML,
      emailTemplateName: emailTemplate.emailTemplateName,
      statusId: emailTemplate.status,
      subject: emailTemplate.subject,
      cc: emailTemplate.cc,
      userName: userName,
    };
    let url = `${UrlEnum.emailTemplates}`;
    if (data && data._id) {
      url += `/${data._id}`;
    }
    postData(url, body)
      .then((res) => {
        onClose(true);
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  /**
   *
   * @param file
   * @returns
   */
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   *
   * @param file
   * @returns
   */
  const uploadImage = async (file: any) => {
    const newUploadedImages = uploadedImages.slice();
    const resizedFile = (await resizeImage(file)) as any;
    const base64 = await toBase64(resizedFile);
    const imageObject = {
      file: resizedFile,
      localSrc: URL.createObjectURL(resizedFile),
    };
    newUploadedImages.push(imageObject);
    setUploadedImages(newUploadedImages);
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: base64,
        },
      });
    });
  };

  return (
    <div style={{ margin: 5 }}>
      <AdditionalData
        openMenu={openMenu}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={handleClick}
      />

      <EmailTemplateDetails
        fromOrder={fromOrder}
        emailTemplate={emailTemplate}
        handleChangeTemplateProps={handleChangeTemplateProps}
        handleChangeTemplateStatus={handleChangeTemplateStatus}
        statusEnum={statusEnum}
        handleChangeOnFocus={handleChangeOnFocus}
      />

      <div
        style={{
          border: "1px solid #f1f1f1",
          padding: 10,
        }}
      >
        <Editor
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbarClassName"
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              uploadCallback: uploadImage,
              alt: { present: false, mandatory: false },
            },
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          }}
          editorState={emailTemplate.html}
          onEditorStateChange={onChange}
          onFocus={() => handleChangeOnFocus(Vocabulary.editor)}
        />
      </div>
      {fromOrder ? (
        ""
      ) : (
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            type="submit"
            style={{
              margin: "15px 0px",
              color: theme.palette.whiteColor?.main,
            }}
            size="large"
            onClick={handleSubmit}
          >
            <SaveIcon /> &nbsp;&nbsp;{Vocabulary.save}
          </Button>
        </div>
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
