/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from "../../Components/Modal";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  FormControlLabel,
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fragment, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { UrlEnum } from "../../Utils/Utils";
import { postData } from "../../Services/postData";
import { toast } from "react-toastify";
import style from "./Templates.module.scss";
import theme from "../../Theme/Theme";
type PersonalDataTemplateProps = {
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
  data: any;
};

type FieldType = {
  name: string;
  hint: string;
  required: boolean;
  type: string;
  [key: string]: any;
};

type PersonalDataTemplateState = {
  name: string;
  _id: number;
  fields: Array<FieldType>;
  terms: string;
  paymentInfo: boolean;
  userName?: string | null;
};

const initialState: PersonalDataTemplateState = {
  name: Vocabulary.personalDataTemplate,
  _id: 0,
  fields: [
    {
      name: "",
      hint: "",
      required: true,
      type: "text",
    },
  ],
  terms: Vocabulary.terms,
  paymentInfo: false,
};
const userName = localStorage.getItem("userName")
  ? localStorage.getItem("userName")
  : "";

export default function PersonalDataTemplate({
  open,
  onClose,
  data,
}: PersonalDataTemplateProps) {
  const initialStateClone = JSON.parse(
    JSON.stringify(data ? data : initialState)
  );
  const [state, setState] =
    useState<PersonalDataTemplateState>(initialStateClone);
  const [loading, setLoading] = useState(false);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  /**
   *
   * @param fieldName
   * @param index
   * @param value
   */
  function handleChange(fieldName: string, index: number, value: any | never) {
    const newState = { ...state };
    if (fieldName === "required") {
      newState.fields[index].required = value;
    } else {
      newState.fields[index][fieldName] = value;
    }
    setState(newState);
  }

  /**
   *
   * @param event
   */
  function handleChangeTerms(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setState({ ...state, terms: event.target.value });
  }

  /**
   *
   * @param event
   */
  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, name: event.target.value });
  }

  /**
   * save template
   */
  function saveTemplate() {
    setLoading(true);
    let url = UrlEnum.personalDataTemplates;
    if (parseInt(state._id.toString()) !== 0) {
      url = `${url}/${state._id}`;
    }
    state.userName = userName;
    postData(url, state)
      .then((res) => {
        onClose(true);
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  return (
    <>
      <Modal
        title={state.name}
        open={open}
        onClose={() => onClose(false)}
        restModalProps={{
          maxWidth: "xl",
        }}
        maxWidth={1535}
      >
        <br />
        <Grid container spacing={4}>
          <Grid item md={10} sm={10}>
            <TextField
              fullWidth
              label={Vocabulary.templateName}
              value={state.name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item md={2} sm={2}>
            <Checkbox
              style={{ color: theme.palette.primary?.main }}
              checked={state.paymentInfo}
              onClick={(e: any) =>
                setState({
                  ...state,
                  paymentInfo: e.target.checked,
                })
              }
            />
            {Vocabulary.paymentInfo}
          </Grid>
        </Grid>
        <br />
        <br />
        <fieldset className={style.fieldset}>
          <legend>{Vocabulary.fieldGroup}</legend>
          {state.fields.map((el: FieldType, index: number) => (
            <Fragment key={index}>
              <Grid container gap={2}>
                <Grid item md={3} sm={3}>
                  <TextField
                    fullWidth
                    label={Vocabulary.name}
                    value={el.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange("name", index, event.target.value)
                    }
                  />
                </Grid>
                <Grid item md={3} sm={3}>
                  <TextField
                    fullWidth
                    label={Vocabulary.hint}
                    value={el.hint}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange("hint", index, event.target.value)
                    }
                  ></TextField>
                </Grid>
                <Grid item md={2} sm={2}>
                  <FormControlLabel
                    label={Vocabulary.required}
                    control={
                      <Checkbox
                        checked={el.required}
                        name="required"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChange("required", index, event.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item md={3} sm={3}>
                  <FormControl sx={{ width: "80%" }}>
                    <InputLabel id="demo-simple-select-label">
                      {Vocabulary.type}
                    </InputLabel>
                    <Select
                      name="type"
                      value={el.type}
                      onChange={(event: SelectChangeEvent) =>
                        handleChange("type", index, event.target.value)
                      }
                    >
                      <MenuItem value="text">{Vocabulary.text}</MenuItem>
                      <MenuItem value="email">{Vocabulary.email}</MenuItem>
                      <MenuItem value="number">{Vocabulary.number}</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton
                    sx={{ marginTop: 1 }}
                    onClick={() => {
                      state.fields.splice(index, 1);
                      setState({ ...state });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
            </Fragment>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              const initialStateClone = JSON.parse(
                JSON.stringify(initialState)
              );
              const stateClone = JSON.parse(JSON.stringify(state));
              stateClone.fields.push(initialStateClone.fields[0]);
              setState(stateClone);
            }}
          >
            <AddIcon /> &nbsp;&nbsp;{Vocabulary.addField}
          </Button>
        </fieldset>

        <div style={{ marginTop: 20 }}>
          <label>{Vocabulary.personalDataCollectionAgreement}</label>
          <textarea
            name="terms"
            style={{ width: "100%", borderColor: "#ccc" }}
            rows={5}
            value={state.terms}
            onChange={handleChangeTerms}
          />
        </div>

        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            variant="contained"
            sx={{ color: theme.palette.whiteColor?.main }}
            color="primary"
            onClick={saveTemplate}
          >
            <SaveIcon /> &nbsp;&nbsp;{Vocabulary.save}
          </Button>
        </div>
      </Modal>
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
