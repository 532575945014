import { Button } from "@mui/material";
import Modal from "../../Components/Modal";
import theme from "../../Theme/Theme";
import { Vocabulary } from "../../Utils/Vocabulary";

type OrderModalProps = {
  title: string;
  openModal: boolean;
  closeModal: () => void;
  handleModifyValues: () => void;
};
export default function OrderModal(props: OrderModalProps) {
  return (
    <Modal open={props.openModal} onClose={props.closeModal} title={""}>
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "20px" }}>{props.title}</p>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: 20, color: theme.palette.whiteColor?.main }}
          size="large"
          onClick={() => props.handleModifyValues()}
        >
          {Vocabulary.yes}
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{
            margin: 20,
            color: theme.palette.whiteColor?.main,
            backgroundColor: theme.palette.errorColor?.main,
          }}
          size="large"
          onClick={props.closeModal}
        >
          {Vocabulary.no}
        </Button>
      </div>
    </Modal>
  );
}
