/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, FormControl, FormLabel, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

type ShowPersonalDataInQuestionnaireProps = {
  personalDataTemplates: Array<any>;
  personalDataTemplateId: Array<any>;
  handleChangePersonalDataTemplates: (event: any, pdt: any) => void;
};
export default function ShowPersonalDataInQuestionnaire(
  props: ShowPersonalDataInQuestionnaireProps
) {
  return (
    <Paper style={{ padding: 15, margin: "15px 0px", width: "99%" }}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          {Vocabulary.personalDataTemplates}
        </FormLabel>
        {props.personalDataTemplates.map((pdt: any, index: number) => {
          return (
            <div
              key={`personalDataTemplates${index}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                color="primary"
                checked={
                  props.personalDataTemplateId.find(
                    (id: string) => pdt._id === id
                  )
                    ? true
                    : false
                }
                onChange={(e) =>
                  props.handleChangePersonalDataTemplates(e, pdt)
                }
              />
              {pdt.name}
            </div>
          );
        })}
      </FormControl>
    </Paper>
  );
}
