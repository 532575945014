/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Modal from "../../Components/Modal";
import { getData } from "../../Services/getData";
import { UrlEnum } from "../../Utils/Utils";
import { toast } from "react-toastify";
import ShowPersonalDataInQuestionnaire from "./ShowPersonalDataInQuestionnaire";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Theme/Theme";
import { Button } from "@mui/material";
import { postData } from "../../Services/postData";
import SaveIcon from "@mui/icons-material/Save";

type EditPersonalDataForQuestionnairesProps = {
  editMoreQuestionnairesModal: boolean;
  editMoreQuestionnaires: Array<any>;
  handleOpenEditPersonalDataFroQuestionnaires: () => void;
  title: string;
};
export default function EditPersonalDataForQuestionnaires(
  props: EditPersonalDataForQuestionnairesProps
) {
  const {
    editMoreQuestionnairesModal,
    editMoreQuestionnaires,
    handleOpenEditPersonalDataFroQuestionnaires,
    title,
  } = props;
  const [personalDataTemplates, setPersonalDataTemplates] = useState([]);
  const [personalDataTemplatesIds, setPersonalDataTemplatesIds] = useState([]);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    if (personalDataTemplates.length === 0) {
      getPersonalDataTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  async function getPersonalDataTemplates() {
    getData(UrlEnum.personalDataTemplates)
      .then((res) => {
        setPersonalDataTemplates(res.data);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
      });
  }

  function handleChangePersonalDataTemplates(event: any, pdt: any) {
    if (event.target.checked)
      setPersonalDataTemplatesIds([
        ...personalDataTemplatesIds,
        pdt._id,
      ] as any);
    else {
      const ids = [...personalDataTemplatesIds];
      const index = ids.findIndex((id: string) => id === pdt._id);
      if (index >= 0) {
        ids.splice(index, 1);
        setPersonalDataTemplatesIds(ids);
      }
    }
  }

  function savePersonalDataTemplates() {
    const body = {
      ids: editMoreQuestionnaires,
      personalDataTemplateId: personalDataTemplatesIds,
      userName: userName,
    };
    postData(`${UrlEnum.questionnaire}/updateMany/personalData`, body)
      .then((res) => {
        handleOpenEditPersonalDataFroQuestionnaires();
        notifySuccess(Vocabulary.saveSuccess);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
      });
  }
  return (
    <Modal
      open={editMoreQuestionnairesModal}
      onClose={handleOpenEditPersonalDataFroQuestionnaires}
      title={title}
    >
      <ShowPersonalDataInQuestionnaire
        personalDataTemplates={personalDataTemplates}
        personalDataTemplateId={personalDataTemplatesIds}
        handleChangePersonalDataTemplates={handleChangePersonalDataTemplates}
      />
      <Button
        variant="contained"
        size="large"
        style={{
          color: theme.palette.whiteColor?.main,
          backgroundColor: theme.palette.primary?.main,
          marginTop: 20,
        }}
        onClick={savePersonalDataTemplates}
      >
        <SaveIcon /> &nbsp;&nbsp;{Vocabulary.save}
      </Button>
    </Modal>
  );
}
