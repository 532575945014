import { createContext, useEffect, useRef, useState } from "react";
import { getData } from "../Services/getData";
import { UrlEnum } from "../Utils/Utils";

function getOrdersNumber() {
  const promise = new Promise((resolve, reject) => {
    getData(`${UrlEnum.orders}/getOrdersNumber/isNotViewed`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
}
export const BadgeContext = createContext({
  ordersNumber: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOrdersNumber: (ordersNumber: number) => {},
});

export const BadgeContextProvider = (props: any) => {
  const timeout = useRef(null) as any;
  useEffect(() => {
    if (!timeout.current) {
      getOrdersNumber().then((val) => {
        setOrdersNumber(val as number);
      });
    }

    timeout.current = setInterval(() => {
      getOrdersNumber().then((val) => {
        setOrdersNumber(val as number);
      });
    }, 1000 * 30);

    return () => {
      clearInterval(timeout.current);
    };
  }, []);

  const setOrdersNumber = async (on: number) => {
    setState({ ...state, ordersNumber: on });
  };

  const initState = {
    ordersNumber: 0,
    setOrdersNumber: setOrdersNumber,
  };
  const [state, setState] = useState(initState);

  return (
    <BadgeContext.Provider value={state}>
      {props.children}
    </BadgeContext.Provider>
  );
};
