/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles } from "@mui/styles";
import { createStyles, styled } from "@mui/material/styles";
import {
  AppBar,
  AppBarProps,
  Avatar,
  Breadcrumbs,
  CssBaseline,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import useNoActivity from "../Hooks/useNoActivity";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Vocabulary } from "../Utils/Vocabulary";
import { Logout, Settings, AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuContext } from "../Context/menuContext";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsContext } from "../Context/breadCrumbsContext";
import style from "./Header.module.scss";
import moment from "moment";

type Props = {
  classes: any;
};
let logoutTimeout: any;
const drawerWidth = 240;

interface CustomAppBarProps extends AppBarProps {
  open?: boolean;
}

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<CustomAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 30,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header(props: Props) {
  const { classes } = props;
  const hasActivity = useNoActivity();
  const menuContext = useContext(MenuContext);
  const [showUserOptions, setShowUserOptions] = useState(null);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const navigate = useNavigate();
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  /**
   *
   */
  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        logout();
      }, 1000 * 60 * 15);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActivity]);

  /**
   *
   */
  const menuHandleClick = () => {
    menuContext.setOpen(!menuContext.open);
  };

  /**
   *
   */
  function logout() {
    localStorage.setItem("userId", "");
    localStorage.setItem("userName", "");
    localStorage.setItem("email", "");
    localStorage.setItem("access_token", "");
    localStorage.setItem("roles", "");
    navigate("/login");
  }

  /**
   *
   */
  function handleCloseUserMenu() {
    setShowUserOptions(null);
  }

  /**
   *
   * @param event
   */
  function handleOpenUserMenu(event: any) {
    setShowUserOptions(event.currentTarget);
  }

  return (
    <Box className={style.headerBox}>
      <CssBaseline />
      <CustomAppBar
        position="static"
        className={classes.container}
        open={menuContext.open}
      >
        <Toolbar className={style.headerToolbar}>
          <div className={style.headerToolbarDiv}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 2,
                ...(menuContext.open && { display: "none" }),
              }}
              onClick={menuHandleClick}
            >
              <Avatar className={classes.avatar}>
                <MenuIcon />
              </Avatar>
            </IconButton>
            <div className={classes.image}>
              <img src={"/yzzy-logo.png"} alt="YZZY" width="100%" />
            </div>
            <p>
              <Breadcrumbs separator="/" className={style.headerBreadcrumbs}>
                {breadCrumbsContext.breadCrumbs.map(
                  (name: any, index: number) => {
                    if (index === breadCrumbsContext.breadCrumbs.length - 1)
                      return (
                        <Link
                          key={`${name}_${index}`}
                          underline="none"
                          color="inherit"
                        >
                          {Object.prototype.hasOwnProperty.call(
                            Vocabulary,
                            name
                          )
                            ? Vocabulary[name]
                            : name}
                        </Link>
                      );

                    return (
                      <Link
                        key={`${name}_${index}`}
                        underline="hover"
                        color="inherit"
                        href={
                          name === "orders"
                            ? `/${name}?page=1?perPage=20?filter=Niciun filtru?sort=createdAt,desc?startDate=${moment()
                                .subtract(14, "d")
                                .format(
                                  "YYYY-MM-DD"
                                )}?endDate=${moment().format("YYYY-MM-DD")}`
                            : `/${name}?page=1?perPage=10`
                        }
                      >
                        {Object.prototype.hasOwnProperty.call(Vocabulary, name)
                          ? Vocabulary[name]
                          : name}
                      </Link>
                    );
                  }
                )}
              </Breadcrumbs>
            </p>
          </div>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={(event) => {
              handleOpenUserMenu(event);
            }}
          >
            <Avatar className={classes.avatar}>
              {email ? (
                email.charAt(0).toUpperCase()
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </Avatar>
          </IconButton>
        </Toolbar>
        <Box>
          <Menu
            anchorEl={showUserOptions}
            sx={{ mt: "50px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(showUserOptions)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem>
              <Settings fontSize="large" className={style.menuSettings} />
              <Typography variant="h6">{Vocabulary.settings}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                logout();
              }}
            >
              <Logout fontSize="large" className={style.menuSettings} />
              <Typography variant="h6">{Vocabulary.logout}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </CustomAppBar>
    </Box>
  );
}

const styles = (theme: any) =>
  createStyles({
    container: {
      width: "100%",
      backgroundColor: `${theme.palette.whiteColor?.main} !important`,
      boxShadow: `1px 3px 10px ${theme.palette.blackColor?.main} `,
      [theme.breakpoints.down("lg")]: {},
    },
    image: {
      width: "200px",
      marginTop: "10px",
    },
    avatar: {
      backgroundColor: `${theme.palette.dashboard?.main} !important`,
    },
  });

export default withStyles(styles, { withTheme: true })(Header);
