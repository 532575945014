/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, InputLabel, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import theme from "../../Theme/Theme";
import style from "./Orders.module.scss";
import { UrlEnum } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

type StatusOrderProps = {
  isOrder: boolean;
  status: any;
  handleChangeStatus: (
    id: string | null | undefined,
    value: string | undefined
  ) => void;
  name: string;
  styles?: any;
  noFilter?: any;
};

export default function StatusOrder(props: StatusOrderProps) {
  const { isOrder, status, handleChangeStatus, name, styles, noFilter } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);

  useEffect(() => {
    getData(UrlEnum.orderStatus)
      .then((res) => {
        const response = [...res.data];
        if (noFilter) response.unshift(noFilter);
        setStatusEnum(response);
      })
      .catch((err) => {
        //
      });
  }, []);

  /**
   *
   * @param st
   * @param event
   */
  function handleClick(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  }

  /**
   *
   * @param id
   * @param value
   */
  function handleCloseMenuFilter() {
    if (selected.length !== 0) {
      const value = selected.join(",");
      handleChangeStatus(null, value);
    }
    setAnchorEl(null);
  }

  /**
   *
   * @param id
   * @param value
   */
  function handleClose(id?: string, value?: string) {
    if (value) handleChangeStatus(id, value);
    setAnchorEl(null);
  }

  /**
   *
   * @param id
   * @param value
   */
  function handleAddInUrl(value: string) {
    const newSelected = selected.slice();
    if (value === "Niciun filtru") {
      setSelected([value]);
    } else {
      const noFilterIndex = newSelected.findIndex(
        (sel: string) => sel === "Niciun filtru"
      );
      if (noFilterIndex >= 0) newSelected.splice(noFilterIndex, 1);
      const index = newSelected.findIndex((sel: string) => sel === value);
      if (index >= 0) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(value);
      }
      setSelected(newSelected);
    }
  }

  /**
   *
   * @returns
   */
  function getColor() {
    const st = statusEnum.find(
      (stEnum: any) => stEnum.name.toLowerCase() === status.toLowerCase()
    );
    if (st) {
      return st.color;
    }
    return theme.palette.grayColor?.main;
  }

  return (
    <div
      className={styles ? style.statusOrder : style.applyFilters}
      style={{ backgroundColor: styles ? getColor() : "" }}
      onClick={(e) => (styles ? handleClick(e) : "")}
    >
      <InputLabel
        style={{
          marginRight: 20,
          fontSize: 15,
          color: styles ? styles.color : "",
        }}
      >
        {Vocabulary[name]}
      </InputLabel>
      <Button
        variant="contained"
        size="large"
        style={{
          backgroundColor: getColor(),
          color: theme.palette.blackColor?.main,
          boxShadow: styles ? styles.boxShadow : "",
        }}
        onClick={(e) => handleClick(e)}
      >
        {status}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => (isOrder ? handleClose() : handleCloseMenuFilter())}
        PaperProps={{
          style: {
            width: styles ? styles.width : "",
          },
        }}
      >
        {isOrder ? (
          <>
            {statusEnum.map((stEnum: any, index: number) => (
              <MenuItem
                key={`status_${index}`}
                onClick={() => handleClose(stEnum._id, stEnum.name)}
              >
                {stEnum?.name.toUpperCase()}
              </MenuItem>
            ))}
          </>
        ) : (
          <>
            {statusEnum.map((stEnum: any, index: number) => (
              <MenuItem
                key={`statusEnum_${index}`}
                onClick={() => handleAddInUrl(stEnum.name)}
              >
                <Checkbox
                  checked={selected.indexOf(stEnum.name) > -1}
                  className={style.checkbox}
                />
                {stEnum?.name.toUpperCase()}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </div>
  );
}
