/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Question from "./Question";
import { QuestionsContext } from "../../Context/questionsContext";
import { postData } from "../../Services/postData";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "../../Services/getData";
import QuestionnaireMenu from "./QuestionnaireMenu";
import ShowPersonalDataInQuestionnaire from "./ShowPersonalDataInQuestionnaire";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "./Questionnaire.module.scss";

export default function Questionnaire() {
  const questionsContext = useContext(QuestionsContext);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [questionnaire, setQuestionnaire] = useState({
    id: "0",
    questionnaireName: "",
    personalDataTemplateId: [""],
  });
  const [personalDataTemplates, setPersonalDataTemplates] = useState([]);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [loading, setLoading] = useState(false);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    let id = location.pathname;
    id = id.replace("/questionnaires/", "");
    if (id === "0") {
      breadCrumbsContext.setBreadCrumbs([
        "questionnaires",
        `${Vocabulary.newQuestionnaire}`,
      ]);
    }
    if (id !== "0" && questionnaire.questionnaireName === "") {
      getQuestionnaire(id);
    }
    if (personalDataTemplates.length === 0) {
      getPersonalDataTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param id
   */
  async function getQuestionnaire(id: string) {
    setLoading(true);
    getData(`${UrlEnum.questionnaire}/${id}`)
      .then((res) => {
        const newQuestionnaire = {
          id: res.data[0]._id,
          questionnaireName: res.data[0].questionnaireName,
          personalDataTemplateId: res.data[0].personalDataTemplateId
            ? res.data[0].personalDataTemplateId
            : [""],
        };
        breadCrumbsContext.setBreadCrumbs([
          "questionnaires",
          `${newQuestionnaire.questionnaireName}`,
        ]);
        setQuestionnaire(newQuestionnaire);
        questionsContext.setQuestions(res.data[0].questions);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   */
  async function getPersonalDataTemplates() {
    getData(UrlEnum.personalDataTemplates)
      .then((res) => {
        setPersonalDataTemplates(res.data);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   */
  function handleChangeOpenAddTemplate() {
    setOpenAddTemplate(!openAddTemplate);
  }
  /**
   *
   */
  function saveQuestionnaires(event: any) {
    event.preventDefault();
    setLoading(true);
    const personalDataTemplateId = questionnaire.personalDataTemplateId.filter(
      (ids) => ids !== ""
    );
    if (personalDataTemplateId.length !== 2) {
      notifyError(Vocabulary.selectPersonalDataError);
      setLoading(false);
      return;
    }
    const newQuestions = questionsContext.questions.map((question) => ({
      answers: question.answers,
      answerType: question.answerType,
      images: question.images ? question.images : [],
      openCollapse: question.openCollapse,
      question: question.question,
    }));
    const newQuestionnaire = {
      questionnaireName: questionnaire.questionnaireName,
      questions: newQuestions,
      personalDataTemplateId: personalDataTemplateId,
      userName: userName,
    };
    let url = UrlEnum.questionnaire;
    if (questionnaire.id !== "0") {
      url = `${UrlEnum.questionnaire}/${questionnaire.id}`;
    }
    postData(url, newQuestionnaire)
      .then((res) => {
        if (questionnaire.id === "0") {
          navigate(`${LocalUrlEnum.questionnaires}${res.data._id}`);
        }
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  /**
   *
   * @param templateName
   */
  function saveAsTemplate(templateName: string) {
    setLoading(true);
    const template = {
      templateName: templateName,
      questions: questionsContext.questions,
      userName: userName,
    };
    postData(UrlEnum.questionnaireTemplates, template)
      .then((res) => {
        handleChangeOpenAddTemplate();
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        handleChangeOpenAddTemplate();
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  /**
   *
   * @param event
   */
  function handleChangePersonalDataTemplates(event: any, pdt: any) {
    if (event.target.checked)
      setQuestionnaire({
        ...questionnaire,
        personalDataTemplateId: [
          ...questionnaire.personalDataTemplateId,
          pdt._id,
        ],
      });
    else {
      const ids = [...questionnaire.personalDataTemplateId];
      const index = ids.findIndex((id: string) => id === pdt._id);
      if (index >= 0) {
        ids.splice(index, 1);
        setQuestionnaire({
          ...questionnaire,
          personalDataTemplateId: ids,
        });
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={saveQuestionnaires}>
        <Grid container spacing={2} style={{ marginRight: 0 }}>
          <QuestionnaireMenu
            isQuestionnaireTemplate={false}
            openAddTemplate={openAddTemplate}
            questionnaireName={questionnaire.questionnaireName}
            saveAsTemplate={saveAsTemplate}
            handleChangeOpenAddTemplate={handleChangeOpenAddTemplate}
          />
          <Grid item xs={10} className={style.questionnaireContainer}>
            <Paper className={style.questionnaireContainerPaper}>
              <TextField
                type="text"
                name="questionnaireName"
                label={Vocabulary.questionnaireName}
                variant="standard"
                value={questionnaire.questionnaireName}
                onChange={(e) =>
                  setQuestionnaire({
                    ...questionnaire,
                    questionnaireName: e.target.value,
                  })
                }
                required
                fullWidth
              />
            </Paper>
            {questionsContext.questions.map((question, index) => (
              <Paper
                key={`questionsContext${index}`}
                className={style.question}
              >
                <Question
                  questionNumber={index}
                  questions={questionsContext.questions}
                />
              </Paper>
            ))}
            <ShowPersonalDataInQuestionnaire
              personalDataTemplates={personalDataTemplates}
              personalDataTemplateId={questionnaire.personalDataTemplateId}
              handleChangePersonalDataTemplates={
                handleChangePersonalDataTemplates
              }
            />
          </Grid>
        </Grid>
      </form>
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
