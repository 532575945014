import UserModal from "./UserModal";
import { Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UrlEnum } from "../../Utils/Utils";
import { deleteData } from "../../Services/deleteData";
import { Vocabulary } from "../../Utils/Vocabulary";

type DeleteUserProps = {
  setShowDeleteUserModal: (showDeleteUserModal: boolean) => void;
  setLoading: (loading: boolean) => void;
  getUsers: () => void;
  userId: string | null;
  showDeleteUserModal: boolean;
};
function DeleteUser(props: DeleteUserProps) {
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);
  const {
    setShowDeleteUserModal,
    setLoading,
    getUsers,
    userId,
    showDeleteUserModal,
  } = props;
  const userID = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : "";
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  function closeModal() {
    setShowDeleteUserModal(false);
    setLoading(false);
  }
  const handleDeleteUser = () => {
    setLoading(true);
    const url = `${UrlEnum.users}/${userId}/${userID}/${userName}`;
    deleteData(url)
      .then((res) => {
        if (res.data === "sameUser") {
          notifyError("Nu puteți să ștergeți propriul cont!");
        } else if (res.data === "cantDeleteThisUser") {
          notifyError(
            "Nu puteți să ștergeți acest utilizator deoarece este singurul administrator"
          );
        } else {
          getUsers();
          notifySuccess(Vocabulary.deleteSuccess);
        }
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.deleteError);
      });
  };

  return (
    <UserModal
      open={showDeleteUserModal}
      handleClose={closeModal}
      title={Vocabulary.deleteUser}
      handleSubmit={handleDeleteUser}
      submitButton={Vocabulary.yes}
      cancelButton={Vocabulary.no}
    >
      <Typography style={{ margin: 10, padding: 10 }}>
        {Vocabulary.deleteUserText}
      </Typography>
      <ToastContainer />
    </UserModal>
  );
}
export default DeleteUser;
