/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Collapse,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnswersTable from "./AnswersTable";
import { useContext, useState } from "react";
import {
  QuestionnaireState,
  QuestionsContext,
} from "../../Context/questionsContext";
import AddImages from "./AddImages";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "./Questionnaire.module.scss";

type QuestionState = {
  questionNumber: number;
  answerText?: string;
  questions: Array<QuestionnaireState>;
};
const answerType = [
  {
    name: "singleChoice",
    label: Vocabulary.singleChoice,
  },
  {
    name: "multipleChoice",
    label: Vocabulary.multipleChoice,
  },
];
export default function Question(props: QuestionState) {
  const { questionNumber, questions, answerText } = props;
  const [openAddImagesModal, setOpenAddImagesModal] = useState(false);
  const questionsContext = useContext(QuestionsContext);

  /**
   *
   */
  function handleCloseOpenAddImagesModal() {
    setOpenAddImagesModal(false);
  }
  /**
   *
   * @param e
   */
  function handleChangeQuestionName(e: any) {
    questions[questionNumber].question = e.target.value;
    //change state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }
  /**
   *
   * @param questionNumber
   */
  function handleChangeCollapse(questionNumber: number) {
    questions[questionNumber].openCollapse =
      !questions[questionNumber].openCollapse;
    //change state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   * @param e
   */
  function handleChangeAnswerType(e: any) {
    questions[questionNumber].answerType = e.target.value;
    //change state
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  /**
   *
   */
  function deleteQuestion() {
    questions.splice(questionNumber, 1);
    const newQuestions = questionsContext.questions.slice();
    questionsContext.setQuestions(newQuestions);
  }

  return (
    <div>
      <div className={style.questions}>
        <h2
          style={{ margin: 10 }}
          onClick={() => handleChangeCollapse(questionNumber)}
        >
          {answerText
            ? `${Vocabulary.answer} "${answerText}" - ${Vocabulary.question} ${
                questionNumber + 1
              }`
            : ` ${Vocabulary.question} ${questionNumber + 1}`}
        </h2>
        <div>
          <Button
            size="large"
            style={{
              margin: "0px 10px 30px 10px",
              verticalAlign: "center",
            }}
            onClick={() => setOpenAddImagesModal(true)}
          >
            {Vocabulary.addImages} (
            {questions[questionNumber].images
              ? questions[questionNumber].images.length
              : 0}
            )
          </Button>
          <DeleteIcon className={style.btnIcon} onClick={deleteQuestion} />
          {questions[questionNumber].openCollapse ? (
            <KeyboardArrowUpIcon
              className={style.btnIcon}
              onClick={() => handleChangeCollapse(questionNumber)}
            />
          ) : (
            <KeyboardArrowDownIcon
              className={style.btnIcon}
              onClick={() => handleChangeCollapse(questionNumber)}
            />
          )}
        </div>
      </div>
      <Collapse
        in={questions[questionNumber].openCollapse}
        unmountOnExit
        style={{ marginTop: -10 }}
      >
        <div style={{ textAlign: "end" }}>
          <ToggleButtonGroup
            value={questions[questionNumber].answerType}
            onChange={handleChangeAnswerType}
          >
            {answerType.map((at: any, index: number) => (
              <ToggleButton key={`answerType${index}`} value={at.name}>
                {at.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <TextField
          type="text"
          name="question"
          label={Vocabulary.question}
          style={{ margin: "10px 0px" }}
          value={questions[questionNumber].question}
          onChange={(e) => handleChangeQuestionName(e)}
          required
          fullWidth
        />
        {questions[questionNumber].answers.length !== 0 ? (
          <AnswersTable answers={questions[questionNumber].answers} />
        ) : (
          ""
        )}
      </Collapse>
      <AddImages
        openModal={openAddImagesModal}
        handleCloseModal={handleCloseOpenAddImagesModal}
        question={questions[questionNumber]}
      />
    </div>
  );
}
