import "./App.css";
import { MenuContextProvider } from "./Context/menuContext";
import { QuestionsContextProvider } from "./Context/questionsContext";
import Router from "./Router/Router";

function App() {
  return (
    <MenuContextProvider>
      <QuestionsContextProvider>
        <Router />
      </QuestionsContextProvider>
    </MenuContextProvider>
  );
}

export default App;
