/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Modal from "../../Components/Modal";
import { DeviceState } from "./Device";
import { getData } from "../../Services/getData";
import { UrlEnum } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Theme/Theme";
import SaveIcon from "@mui/icons-material/Save";

type ChooseDevicesModalProps = {
  state: DeviceState;
  setState: (state: DeviceState) => void;
  devices: Array<any>;
  open: boolean;
  handleClose: () => void;
  setLoading: (loading: boolean) => void;
};
function ChooseDevicesModal(props: ChooseDevicesModalProps) {
  const [chosenDevices, setChosenDevices] = useState("");
  const notifyError = (message: string) => toast.error(message);

  /**
   *
   * @param {*} e
   */
  function handleChangeRadioButton(e: any) {
    setChosenDevices(e.target.value);
  }

  /**
   *
   * @param value
   */
  function handleSubmit(value: any) {
    props.setLoading(true);
    const find = props.devices.find((dev) => dev.name === value);
    if (find) {
      const deviceUrl = `${UrlEnum.getGSMDevice}/${find.url}`;
      getData(deviceUrl)
        .then((response) => {
          if (response.data) {
            const newState = Object.assign({}, props.state) as any;
            const released =
              response.data.spec_detail[1].specs[1].value.split(".");
            newState.released = new Date(released[1]);
            newState.deviceName = response.data.title;
            newState.model = response.data.title.substring(
              response.data.title.indexOf(" ") + 1
            );
            newState.manufacturer = response.data.title.substring(
              0,
              response.data.title.indexOf(" ")
            );
            const storage =
              response.data.spec_detail[5].specs[1].value.split(",");
            const colors = response.data.spec_detail[12]
              ? response.data.spec_detail[12].specs[0].value.split(",")
              : response.data.spec_detail[11].specs[0].value.split(",");
            newState.defaultStorages = storage.map((s: string) => s.trim());
            newState.defaultColors = ["Orice culoare", ...colors];
            newState.defaultColors = newState.defaultColors.map((dc: string) =>
              dc.trim()
            );
            newState.configurations = [];
            props.setState(newState);
            props.handleClose();
            props.setLoading(false);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          notifyError("Eroare la preluat datele despre dispozitiv!");
        });
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      title={Vocabulary.chooseDevice}
    >
      <ToastContainer />
      <div style={{ textAlign: "center" }}>
        <RadioGroup
          value={chosenDevices}
          onChange={handleChangeRadioButton}
          style={{ marginLeft: 25 }}
        >
          {props.devices.map((device: any) => {
            return (
              <FormControlLabel
                key={`${device.name}_radio`}
                value={device.name}
                control={<Radio />}
                label={device.name}
              />
            );
          })}
        </RadioGroup>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: 20, color: theme.palette.whiteColor?.main }}
          size="large"
          onClick={() => handleSubmit(chosenDevices)}
        >
          <SaveIcon /> &nbsp;&nbsp; {Vocabulary.save}
        </Button>
      </div>
    </Modal>
  );
}

export default ChooseDevicesModal;
