/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
type DeviceDetailsProps = {
  details: any;
  handleChangeDeviceDetailsValue: (key: string, value: string | any) => void;
};
export default function DeviceDetails(props: DeviceDetailsProps) {
  const { details, handleChangeDeviceDetailsValue } = props;
  const deviceDetails = Object.entries(details);
  return (
    <Grid container spacing={2} style={{ margin: 10 }}>
      {deviceDetails.map((detail) => (
        <Grid
          key={`detail_${detail[1]}`}
          item
          xs={6}
          md={3}
          lg={3}
          style={{ fontSize: "15px" }}
        >
          <TextField
            type="text"
            name={detail[0]}
            label={Vocabulary[detail[0]]}
            variant="standard"
            fullWidth
            value={detail[1]}
            onChange={(e) =>
              handleChangeDeviceDetailsValue(detail[0], e.target.value)
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}
